import React from 'react';

import { FunctionButtonVariants } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useMyUser } from '~/services/auth';
import { formatDate } from '~/services/dateTime';
import { getNumberPartFromGlobalId } from '~/services/gql';

import { useSetHerriotSettingsModal } from '~/entities/companies';
import { CompanyFragment } from '~/entities/companies/gql/fragments/company.graphql';
import { CompaniesQueryVariables } from '~/entities/companies/gql/queries/companies.graphql';

import { Tile, TileProps } from '~/features/layouts';

import { ColorVariants } from '~/styles/__generated__/token-variants';

import { CompanyTileContextMenu } from '../CompanyTileContextMenu';
import { CardBlock } from './components/CardBlock';
import styles from './index.module.scss';

interface Props extends Partial<TileProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Company to render
   */
  company: CompanyFragment | SkeletonPlaceholder;
  /**
   * Variables, used in companies query
   */
  queryVariables: CompaniesQueryVariables;
}

export const CompanyTile: React.FC<Props> = ({
  className,
  company,
  queryVariables,

  ...tileProps
}) => {
  const { isIntegratorByCompanyId } = useMyUser();

  const { open: openSetHerriotSettingsModal } = useSetHerriotSettingsModal();

  const isIntegrator = isIntegratorByCompanyId(company.id);

  const shouldShowLoadDataBadge =
    isIntegrator && !isSkeletonPlaceholder(company) && !company.cowsCount;

  const licensedByElement = (
    <CardBlock
      {...{
        iconVariant: IconVariants.clock,
        tooltip: 'Дата окончания лицензии',
        value: formatDate(company.licensedBy),
      }}
    />
  );

  const cowsCountElement = (
    <CardBlock
      {...{
        iconVariant: IconVariants.cow,
        tooltip: 'Животные',
        value: company.cowsCount,
      }}
    />
  );

  return (
    <Tile
      {...{
        className,
        title: company.name,
        isDisabled: company.isLockedForWrite,
        topBadgeProps: shouldShowLoadDataBadge && {
          iconVariant: IconVariants.warningCircleFilled,
          color: ColorVariants.warning,
          children: 'Загрузите данные на ферму',
        },
        linkProps: {
          to: '/$companyId/user/production-calendar',
          params: {
            companyId: getNumberPartFromGlobalId(company.id),
          },
        },
        bottomContent: (
          <div className={styles.info}>
            <CardBlock
              {...{
                iconVariant: IconVariants.user,
                tooltip: 'Пользователи',
                value: company.usersCount,
              }}
            />
            {shouldShowLoadDataBadge ? licensedByElement : cowsCountElement}
            {!shouldShowLoadDataBadge &&
              (isIntegrator || isSkeletonPlaceholder(company)) && (
                <>
                  <CardBlock
                    {...{
                      iconVariant: IconVariants.clipboardList,
                      tooltip: 'Команды',
                      value: company.blueprintsCount,
                    }}
                  />
                  {licensedByElement}
                </>
              )}
          </div>
        ),
        renderContextMenuButton: contextMenuButtonProps =>
          !isSkeletonPlaceholder(company) &&
          isIntegrator && (
            <CompanyTileContextMenu
              {...{
                company,
                queryVariables,
                ...contextMenuButtonProps,
              }}
            />
          ),
        functionButtonProps: isIntegrator &&
          !isSkeletonPlaceholder(company) && {
            tooltip: (
              <div className="grid gap-4">
                {company.isHerriotIntegrationSet && (
                  <Typography
                    variant={TypographyVariants.descriptionLargeStrong}
                  >
                    Настроена передача данных в&nbsp;Хорриот
                  </Typography>
                )}
                {!company.isHerriotIntegrationSet && (
                  <Typography
                    variant={TypographyVariants.descriptionLargeStrong}
                  >
                    Не настроена передача данных в&nbsp;Хорриот
                  </Typography>
                )}

                <Typography variant={TypographyVariants.descriptionLarge}>
                  Нажмите, чтобы проверить настройки
                </Typography>
              </div>
            ),
            iconVariant: company.isHerriotIntegrationSet
              ? IconVariants.herriotConnected
              : IconVariants.herriotDisabled,
            variant: company.isHerriotIntegrationSet
              ? FunctionButtonVariants.success
              : FunctionButtonVariants.failure,
            onPress: () => openSetHerriotSettingsModal({ company }),
          },
        ...tileProps,
      }}
    />
  );
};
