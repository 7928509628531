import React from 'react';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import { useArkaNavigation } from '~/services/navigation';

import { ReproductionCrReportFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrReport.graphql';
import { ReproductionCrReportsQueryVariables } from '~/entities/reproductionCrReports/gql/queries/reproductionCrReports.graphql';
import { RoleBadges } from '~/entities/roles';

import { Tile, TileProps } from '~/features/layouts';

import { ReproductionCrReportTileMenu } from '../ReproductionCrReportTileMenu';

interface Props extends Partial<TileProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render
   */
  reproductionCrReport: ReproductionCrReportFragment | SkeletonPlaceholder;
  /**
   * Gql custom reports query variables
   */
  queryVariables: ReproductionCrReportsQueryVariables;
}

export const ReproductionCrReportTile: React.FC<Props> = ({
  className,
  reproductionCrReport,
  queryVariables,

  ...tileProps
}) => {
  const { urlCompanyId } = useArkaNavigation();

  return (
    <Tile
      {...{
        className,
        title: reproductionCrReport.name,
        linkProps: {
          to: '/$companyId/user/analytics/reproduction/cr/$reproductionCrReportId',
          params: {
            reproductionCrReportId: reproductionCrReport.id,
            companyId: urlCompanyId,
          },
        },
        bottomContent: <RoleBadges roles={reproductionCrReport.roles} />,
        renderContextMenuButton: contextMenuButtonProps =>
          !isSkeletonPlaceholder(reproductionCrReport) && (
            <ReproductionCrReportTileMenu
              {...{
                reproductionCrReport,
                queryVariables,
                ...contextMenuButtonProps,
              }}
            />
          ),
        ...tileProps,
      }}
    />
  );
};
