import React from 'react';

import { RunType } from '@graphql-types';
import clsx from 'clsx';

import { AsyncList, AsyncListProps } from '~/shared/components/AsyncList';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';

import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';

import { useTileSize } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { UseBlueprintFiltersInterface } from '../../hooks';
import { BlueprintTile } from '../BlueprintTile';

const BLUEPRINT_TILES_DEFAULT_COUNT = 4;

interface Props extends Partial<AsyncListProps<BlueprintFragment>> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Blueprints to render
   */
  blueprints: BlueprintFragment[];
  /**
   * Blueprints run type
   */
  runType: RunType;
  /**
   * Called, when user clicked on a button to delete blueprint
   */
  onDelete?: (blueprintId: string) => void;
  /**
   * Hook result for managing filters
   */
  blueprintFiltersInterface: UseBlueprintFiltersInterface;
}

export const BlueprintTilesList: React.FC<Props> = ({
  className,
  blueprints,
  runType,
  onDelete,
  blueprintFiltersInterface,

  ...asyncProps
}) => {
  const { isSearchActive } = blueprintFiltersInterface;

  const { tileSize, tilesGridClassName } = useTileSize(runType);

  return (
    <AsyncList<BlueprintFragment>
      {...{
        className: clsx(tilesGridClassName, className),
        skeletonItemsCount: BLUEPRINT_TILES_DEFAULT_COUNT,
        wrapperTag: 'div',
        items: blueprints,
        shouldWrapNoItemsMessage: false,
        isSearchActive,
        renderItemsWrapper: items => (
          <div
            className={clsx(
              layoutStyles.limitedContainer,
              'container-inline-size'
            )}
          >
            {items}
          </div>
        ),
        renderNoItemsMessageWrapper: noItemsMessage => (
          <div
            className={clsx(
              layoutStyles.fillLeftoverHeightContainer,
              layoutStyles.limitedContainer,
              panelStyles.panel,
              'p-24 grid place-items-center'
            )}
          >
            {noItemsMessage}
          </div>
        ),
        noItemsMessage: (
          <DataBlockedMessage
            {...{
              isLarge: true,
              className: 'p-24',
              message: 'Команды пока не добавлены',
            }}
          />
        ),
        noSearchItemsMessage: (
          <DataBlockedMessage
            {...{
              isLarge: true,
              className: 'p-24',
              message: 'Компании не найдены',
              description: 'По выбранным фильтрам не найдено карточек',
            }}
          />
        ),
        renderItem: blueprint => (
          <BlueprintTile
            key={blueprint.id}
            {...{
              blueprint,
              size: tileSize,
              onDelete: () => onDelete?.(blueprint.id),
            }}
          />
        ),
        ...asyncProps,
      }}
    />
  );
};
