import React from 'react';

import R from 'ramda';

import {
  ContextMenuButton,
  PartialContextMenuButtonProps,
} from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';

import { useEditCustomReportModal } from '~/entities/customReports';
import { CustomReportFragment } from '~/entities/customReports/gql/fragments/customReport.graphql';
import { useDeleteCustomReportMutation } from '~/entities/customReports/gql/mutations/deleteCustomReport.graphql';
import {
  CustomReportsDocument,
  CustomReportsQueryVariables,
} from '~/entities/customReports/gql/queries/customReports.graphql';

interface Props extends PartialContextMenuButtonProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render menu for
   */
  customReport: CustomReportFragment;
  /**
   * Gql custom reports query variables
   */
  queryVariables: CustomReportsQueryVariables;
}

export const CustomReportTileMenu: React.FC<Props> = ({
  customReport,
  className,
  queryVariables,

  ...contextButtonProps
}) => {
  const [deleteCustomReport] = useDeleteCustomReportMutation();

  const confirmDelete = useConfirm();

  const { open: openEditCustomReportModal } = useEditCustomReportModal();

  return (
    <ContextMenuButton
      {...{
        className,
        tooltip: 'Действия с отчётом',
        items: [
          {
            content: 'Настройки',
            onPress: () =>
              openEditCustomReportModal({
                customReport,
                queryVariables,
              }),
          },
          {
            variant: MenuItemVariants.destructive,
            content: 'Удалить',
            onPress: async () => {
              const isConfirmed = await confirmDelete({
                title: 'Удаление отчёта',
                message: (
                  <div className="grid gap-12">
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Вы хотите удалить отчёт{' '}
                      <Typography variant={TypographyVariants.bodySmallStrong}>
                        {customReport.name}
                      </Typography>
                      ?
                    </Typography>
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Это действие невозможно отменить.
                    </Typography>
                  </div>
                ),
                isDelete: true,
              });
              if (isConfirmed) {
                deleteCustomReport({
                  variables: {
                    id: customReport.id,
                  },
                  optimisticResponse: { deleteCustomReport: null },
                  update: R.juxt([
                    makeDeleteFragmentFromQuery({
                      typeName: 'CustomReport',
                      query: CustomReportsDocument,
                      queryName: 'customReports',
                    })(customReport.id),
                    makeDeleteQueriesByNameWithoutVariables(
                      'customReports',
                      queryVariables
                    ),
                  ]),
                });
              }
            },
          },
        ],
        ...contextButtonProps,
      }}
    />
  );
};
