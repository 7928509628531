import { useCallback, useEffect } from 'react';

import { useMatch, useRouter } from '@tanstack/react-router';

import { AppLocalStorageKeys } from '~/shared/constants';

import { useMyUser } from '~/services/auth';
import { getNumberPartFromGlobalId, toGlobalId } from '~/services/gql';

export const useArkaNavigation = () => {
  const { state: routerState, navigate } = useRouter();
  // Router company id
  const companyIdRouteMatch = useMatch({ strict: false });
  const companyIdParam =
    companyIdRouteMatch && 'companyId' in companyIdRouteMatch.params
      ? companyIdRouteMatch.params.companyId
      : '';
  const routerCompanyId =
    companyIdParam && !Number.isNaN(+companyIdParam)
      ? companyIdParam
      : undefined;

  // Saved in storage company id
  const storageCompanyId = localStorage.getItem(
    AppLocalStorageKeys.selectedCompanyId
  );

  // Default company id from user's companies list
  const { myUser, isIntegratorByCompanyId } = useMyUser();

  const defaultCompanyId =
    myUser.companies.edges.length === 1
      ? myUser.companies.edges[0].node.id
      : undefined;

  // Update saved in storage company id, if we update route (router is our source of truth)
  useEffect(() => {
    if (routerCompanyId && routerCompanyId !== storageCompanyId) {
      localStorage.setItem(
        AppLocalStorageKeys.selectedCompanyId,
        routerCompanyId
      );
    }
  }, [routerCompanyId, storageCompanyId]);

  const selectedCompanyId = toGlobalId(
    routerCompanyId ?? storageCompanyId ?? defaultCompanyId,
    'company'
  );

  const selectedCompany = myUser.companies.edges.find(
    c => c.node.id === selectedCompanyId
  )?.node;

  // Redirect to select company page, if company is not found by id
  useEffect(() => {
    if (!!selectedCompanyId && !selectedCompany) {
      navigate({ to: '/select-company' });
    }
  }, [selectedCompanyId, selectedCompany]);

  const selectCompany = useCallback(
    async (companyId: string) => {
      await navigate({
        to: routerState.matches.at(-1)?.fullPath ?? '.',
        params: prev =>
          ({
            ...prev,
            companyId: getNumberPartFromGlobalId(companyId),
          }) as never,
        resetScroll: true,
      });
      // TODO remove after we completely switch to Apollo, now old http service can't refresh queries
      window.location.reload();
    },
    [routerState]
  );

  return {
    navigate,
    selectedCompanyId,
    urlCompanyId: getNumberPartFromGlobalId(selectedCompanyId),
    selectedCompany,
    isIntegratorInSelectedCompany: isIntegratorByCompanyId(selectedCompanyId),
    selectCompany,
  };
};
