import React from 'react';

import clsx from 'clsx';

import { Input, InputVariants } from '~/shared/components/Input';

import { DatePeriodPicker } from '~/services/dateTime';
import { Form } from '~/services/forms';

import { BlueprintAsyncSelect } from '~/entities/blueprints';

import { ReportCardBuilderFormSection } from '~/features/reportCardBuilder';

import contentGriStyles from '~/styles/modules/contentGrid.module.scss';

import { SOMATIC_CELLS_REPORT_SETTINGS_FORM_ID } from '../../constants';
import { SomaticCellsFilterConfigArrayItemCard } from './components';
import { useSomaticCellsReportSettingsForm } from './hooks';
import { SomaticCellsReportSettingsFormProps } from './types';

export const SomaticCellsReportSettingsForm: React.FC<
  SomaticCellsReportSettingsFormProps
> = props => {
  const { className, onSettingsFormSave } = props;

  const {
    formContext,

    filtersArrayItems,
    handleAddNewFilter,
    removeFromFiltersArray,
  } = useSomaticCellsReportSettingsForm(props);

  return (
    <Form
      {...{
        className: clsx(
          'items-start',
          contentGriStyles.autoGridFixedContent,
          className
        ),
        formContext,
        id: SOMATIC_CELLS_REPORT_SETTINGS_FORM_ID,
        onSubmit: formContext.handleSubmit(onSettingsFormSave),
      }}
    >
      <ReportCardBuilderFormSection title="Основное">
        <DatePeriodPicker
          {...{
            name: 'period',
            label: 'Период',
          }}
        />
        <Input
          {...{
            name: 'sccThousandsPerMl',
            label: 'Число соматических клеток, тыс. ед. / см³',
            variant: InputVariants.int,
            shouldChangeValueWithDebounce: true,
            labelProps: {
              tooltip:
                'Если число соматических клеток превысит указанное значение, животное будет считаться заболевшим',
            },
          }}
        />
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection
        {...{
          title: 'Фильтры',

          functionButtonProps: {
            onPress: handleAddNewFilter,
          },
        }}
      >
        <BlueprintAsyncSelect
          {...{
            name: 'blueprintID',
            label: 'Список',
            isClearable: true,
            queryOptions: {
              variables: {
                canBeUsedAsCowsFilter: true,
              },
            },
          }}
        />
        {filtersArrayItems.map((filterConfig, index) => (
          <SomaticCellsFilterConfigArrayItemCard
            key={filterConfig.id}
            {...{
              fieldPrefix: `filters.${index}.`,
              filterConfig: formContext.watch(`filters.${index}`),
              onDelete: () => {
                removeFromFiltersArray(index);
              },
            }}
          />
        ))}
      </ReportCardBuilderFormSection>
    </Form>
  );
};
