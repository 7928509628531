import React from 'react';

import R from 'ramda';

import {
  ContextMenuButton,
  PartialContextMenuButtonProps,
} from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';

import { useEditCustomMilkingReportModal } from '~/entities/customMilkingReports';
import { CustomMilkingReportFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReport.graphql';
import { useDeleteCustomMilkingReportMutation } from '~/entities/customMilkingReports/gql/mutations/deleteCustomMilkingReport.graphql';
import {
  CustomMilkingReportsDocument,
  CustomMilkingReportsQueryVariables,
} from '~/entities/customMilkingReports/gql/queries/customMilkingReports.graphql';

interface Props extends PartialContextMenuButtonProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render menu for
   */
  customMilkingReport: CustomMilkingReportFragment;
  /**
   * Gql custom reports query variables
   */
  queryVariables: CustomMilkingReportsQueryVariables;
}

export const CustomMilkingReportTileMenu: React.FC<Props> = ({
  customMilkingReport,
  className,
  queryVariables,

  ...contextButtonProps
}) => {
  const [deleteCustomMilkingReport] = useDeleteCustomMilkingReportMutation();

  const confirmDelete = useConfirm();

  const { open: openEditCustomMilkingReportModal } =
    useEditCustomMilkingReportModal();

  return (
    <ContextMenuButton
      {...{
        className,
        tooltip: 'Действия с отчётом',
        items: [
          {
            content: 'Редактировать свойства отчёта',
            onPress: () =>
              openEditCustomMilkingReportModal({
                customMilkingReport,
                queryVariables,
              }),
          },
          {
            variant: MenuItemVariants.destructive,
            content: 'Удалить',
            onPress: () => {
              confirmDelete({
                title: 'Удаление отчёта',
                message: (
                  <div className="grid gap-12">
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Вы хотите удалить отчёт{' '}
                      <Typography variant={TypographyVariants.bodySmallStrong}>
                        {customMilkingReport.name}
                      </Typography>
                      ?
                    </Typography>
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Это действие невозможно отменить.
                    </Typography>
                  </div>
                ),
                isDelete: true,
              }).then(isConfirmed => {
                if (!isConfirmed) return;
                deleteCustomMilkingReport({
                  variables: {
                    id: customMilkingReport.id,
                  },
                  optimisticResponse: { deleteCustomMilkingReport: null },
                  update: R.juxt([
                    makeDeleteFragmentFromQuery({
                      typeName: 'CustomMilkingReport',
                      query: CustomMilkingReportsDocument,
                      queryName: 'customMilkingReports',
                      variables: queryVariables,
                    })(customMilkingReport.id),
                    makeDeleteQueriesByNameWithoutVariables(
                      'customMilkingReports',
                      queryVariables
                    ),
                  ]),
                });
              });
            },
          },
        ],
        ...contextButtonProps,
      }}
    />
  );
};
