import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MonitorEntryFragmentDoc } from '../fragments/monitorEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMonitorEntryMutationVariables = Types.Exact<{
  input: Types.CreateMonitorEntryInput;
}>;


export type CreateMonitorEntryMutation = { __typename?: 'Mutation', createMonitorEntry: { __typename: 'MonitorEntry', id: string, name: string, target?: number | null, calculationMethod: Types.CalculationMethodEnum, monitorGroup: { __typename: 'MonitorGroup', id: string }, leftBlueprint: { __typename: 'Blueprint', id: string }, leftBlueprintSourceField?: { __typename: 'SourceField', id: string } | null, rightBlueprint?: { __typename: 'Blueprint', id: string } | null, values: Array<{ __typename: 'MonitorLaunchResultValue', value: number, monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } } | { __typename: 'MonitorLaunchErrorValue', message: string, monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } } | { __typename: 'MonitorLaunchCalculatingValue', monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } }> } };


export const CreateMonitorEntryDocument = gql`
    mutation createMonitorEntry($input: CreateMonitorEntryInput!) {
  createMonitorEntry(input: $input) {
    ...MonitorEntry
  }
}
    ${MonitorEntryFragmentDoc}`;
export type CreateMonitorEntryMutationFn = Apollo.MutationFunction<CreateMonitorEntryMutation, CreateMonitorEntryMutationVariables>;

/**
 * __useCreateMonitorEntryMutation__
 *
 * To run a mutation, you first call `useCreateMonitorEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonitorEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonitorEntryMutation, { data, loading, error }] = useCreateMonitorEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMonitorEntryMutation(baseOptions?: Apollo.MutationHookOptions<CreateMonitorEntryMutation, CreateMonitorEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMonitorEntryMutation, CreateMonitorEntryMutationVariables>(CreateMonitorEntryDocument, options);
      }
export type CreateMonitorEntryMutationHookResult = ReturnType<typeof useCreateMonitorEntryMutation>;
export type CreateMonitorEntryMutationResult = Apollo.MutationResult<CreateMonitorEntryMutation>;
export type CreateMonitorEntryMutationOptions = Apollo.BaseMutationOptions<CreateMonitorEntryMutation, CreateMonitorEntryMutationVariables>;