import React from 'react';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import { useArkaNavigation } from '~/services/navigation';

import { ReproductionHdrAndPrReportFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrReport.graphql';
import { ReproductionHdrAndPrReportsQueryVariables } from '~/entities/reproductionHdrAndPrReports/gql/queries/reproductionHdrAndPrReports.graphql';
import { RoleBadges } from '~/entities/roles';

import { Tile, TileProps } from '~/features/layouts';

import { ReproductionHdrAndPrReportTileMenu } from '../ReproductionHdrAndPrReportTileMenu';

interface Props extends Partial<TileProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render
   */
  reproductionHdrAndPrReport:
    | ReproductionHdrAndPrReportFragment
    | SkeletonPlaceholder;
  /**
   * Gql custom reports query variables
   */
  queryVariables: ReproductionHdrAndPrReportsQueryVariables;
}

export const ReproductionHdrAndPrReportTile: React.FC<Props> = ({
  className,
  reproductionHdrAndPrReport,
  queryVariables,

  ...tileProps
}) => {
  const { urlCompanyId } = useArkaNavigation();

  return (
    <Tile
      {...{
        className,
        title: reproductionHdrAndPrReport.name,
        linkProps: {
          to: '/$companyId/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId',
          params: {
            reproductionHdrAndPrReportId: reproductionHdrAndPrReport.id,
            companyId: urlCompanyId,
          },
        },
        bottomContent: <RoleBadges roles={reproductionHdrAndPrReport.roles} />,
        renderContextMenuButton: contextMenuButtonProps =>
          !isSkeletonPlaceholder(reproductionHdrAndPrReport) && (
            <ReproductionHdrAndPrReportTileMenu
              {...{
                reproductionHdrAndPrReport,
                queryVariables,
                ...contextMenuButtonProps,
              }}
            />
          ),
        ...tileProps,
      }}
    />
  );
};
