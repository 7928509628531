import React from 'react';

import { RadioGroup, RadioGroupVariants } from '~/shared/components/RadioGroup';
import { makeUseEnumSelectItems } from '~/shared/components/Select';

import { useTileSize } from '../../hooks';
import { TileSizes } from '../../types';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Page name to store separate size settings in localStorage
   */
  pageName: string;
}

const TILE_SIZES_DICT: Record<TileSizes, string> = {
  [TileSizes.medium]: 'M',
  [TileSizes.large]: 'L',
};

const useTileSizeItems = makeUseEnumSelectItems(
  TileSizes,
  enumValue => TILE_SIZES_DICT[enumValue as TileSizes]
);

export const TileSizeSelector: React.FC<Props> = ({ className, pageName }) => {
  const { tileSize, setTileSize } = useTileSize(pageName);
  const tileSizeItems = useTileSizeItems();
  return (
    <RadioGroup
      {...{
        className,
        name: 'tileSize',
        label: 'Размер',
        variant: RadioGroupVariants.segmented,
        items: tileSizeItems,
        value: tileSize,
        onValueChange: newValue => setTileSize(newValue as TileSizes),
      }}
    />
  );
};
