import React from 'react';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import { useArkaNavigation } from '~/services/navigation';

import { CustomMilkingReportFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReport.graphql';
import { CustomMilkingReportsQueryVariables } from '~/entities/customMilkingReports/gql/queries/customMilkingReports.graphql';
import { RoleBadges } from '~/entities/roles';

import { Tile, TileProps } from '~/features/layouts';

import { CustomMilkingReportTileMenu } from '../CustomMilkingReportTileMenu';

interface Props extends Partial<TileProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render
   */
  customMilkingReport: CustomMilkingReportFragment | SkeletonPlaceholder;
  /**
   * Gql custom reports query variables
   */
  queryVariables: CustomMilkingReportsQueryVariables;
}

export const CustomMilkingReportTile: React.FC<Props> = ({
  className,
  customMilkingReport,
  queryVariables,

  ...tileProps
}) => {
  const { urlCompanyId } = useArkaNavigation();

  return (
    <Tile
      {...{
        className,
        title: customMilkingReport.name,
        linkProps: {
          to: '/$companyId/user/analytics/milking/by-herd/$customMilkingReportId',
          params: {
            customMilkingReportId: customMilkingReport.id,
            companyId: urlCompanyId,
          },
        },
        bottomContent: <RoleBadges roles={customMilkingReport.roles} />,
        renderContextMenuButton: contextMenuButtonProps =>
          !isSkeletonPlaceholder(customMilkingReport) && (
            <CustomMilkingReportTileMenu
              {...{
                customMilkingReport,
                queryVariables,
                ...contextMenuButtonProps,
              }}
            />
          ),
        ...tileProps,
      }}
    />
  );
};
