import { useCallback } from 'react';

import { BlueprintRoleColor, RunType, ViewKindEnum } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';

import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import {
  normalizeToArrayOrUndefined,
  normalizeToValueOrUndefined,
} from '~/shared/helpers/normalize';
import { getTmpId } from '~/shared/helpers/string';

import { formatDateForBackend } from '~/services/dateTime';
import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { WithSearchParamsValidation } from '~/services/navigation';

import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';
import { useDeleteBlueprintMutation } from '~/entities/blueprints/gql/mutations/deleteBlueprint.graphql';

import {
  BlueprintsPageHeader,
  BlueprintsSearchParams,
  BlueprintTilesList,
  useBlueprintFilters,
} from '~/widgets/blueprintsList';

import layoutStyles from '~/styles/modules/layout.module.scss';

import {
  CalendarEntriesDocument,
  CalendarEntriesQuery,
  CalendarEntriesQueryVariables,
} from './gql/queries/calendarEntries.graphql';
import { useCalendarEntriesPaginatedQuery } from './hooks';

// We have special logic for planned injections,
// but we display them as a normal blueprint card
const PLANNED_INJECTIONS_BLUEPRINT: BlueprintFragment = {
  __typename: 'Blueprint',
  id: getTmpId('plannedInjections'),
  name: 'Рабочий список',
  description: 'Список инъекций на текущий день',
  viewSettings: {
    __typename: 'BlueprintViewSettings',
    kind: ViewKindEnum.Table,
  },
  roles: [
    {
      __typename: 'BlueprintRole',
      id: getTmpId('vet'),
      name: 'Ветеринар',
      color: BlueprintRoleColor.Green,
    },
  ],
};

export const Route = createFileRoute(
  '/$companyId/_layout/user/production-calendar/'
)({
  wrapInSuspense: true,
  component: ProductionCalendarPage,
  validateSearch: ({
    search,
    viewKind,
    roleIds,
  }: WithSearchParamsValidation<BlueprintsSearchParams>) => ({
    roleIds: Array.isArray(roleIds) ? roleIds : [],
    viewKind: viewKind ?? undefined,
    search: search ?? '',
  }),
});

function ProductionCalendarPage() {
  const blueprintFiltersInterface = useBlueprintFilters();
  const { roleIds, viewKind, debouncedSearch, blueprintsDate } =
    blueprintFiltersInterface;

  // Prepare calendar items data
  const queryVariables = {
    since: formatDateForBackend(blueprintsDate, true),
    till: formatDateForBackend(dayjs(blueprintsDate).add(1, 'day'), true),
    roleIDs: normalizeToArrayOrUndefined(roleIds),
    search: normalizeToValueOrUndefined(debouncedSearch),
    viewKind: normalizeToValueOrUndefined(viewKind),
  };

  const { items: calendarEntries, ...asyncProps } =
    useCalendarEntriesPaginatedQuery({
      variables: queryVariables,
    });

  // TODO add viewKind filter on backend
  const filteredCalendarEntries = viewKind
    ? calendarEntries.filter(
        entry => entry.blueprint.viewSettings.kind === viewKind
      )
    : calendarEntries;

  // Blueprint deletion logic
  const [deleteBlueprint] = useDeleteBlueprintMutation();

  const handleDeleteBlueprint = useCallback((blueprintId: string) => {
    deleteBlueprint({
      variables: {
        id: blueprintId,
      },
      optimisticResponse: { deleteBlueprint: null },
      update: R.juxt([
        makeDeleteFragmentFromQuery<
          CalendarEntriesQuery,
          CalendarEntriesQueryVariables
        >({
          typeName: 'CalendarEntry',
          query: CalendarEntriesDocument,
          variables: queryVariables,
        })(blueprintId),
        makeDeleteQueriesByNameWithoutVariables(
          'calendarEntries',
          queryVariables
        ),
      ]),
      refetchQueries: ['calendarEntries'],
    });
  }, []);

  const isToday = dayjs(blueprintsDate).isSame(dayjs(), 'day');

  return (
    <>
      <BlueprintsPageHeader
        {...{
          className: clsx('mb-24', layoutStyles.limitedContainer),
          title: 'Календарь',
          runType: RunType.Schedule,
          blueprintFiltersInterface,
        }}
      />
      <BlueprintTilesList
        {...{
          blueprints: [
            ...wrapConditionalArrayElement(
              !asyncProps.isLoading && isToday && PLANNED_INJECTIONS_BLUEPRINT
            ),
            ...filteredCalendarEntries.map(R.prop('blueprint')),
          ],
          runType: RunType.Schedule,
          onDelete: handleDeleteBlueprint,
          blueprintFiltersInterface,
          ...asyncProps,
        }}
      />
    </>
  );
}
