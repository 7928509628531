import React from 'react';

import R from 'ramda';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import { CustomReportFragment } from '~/entities/customReports/gql/fragments/customReport.graphql';
import { CustomReportsQueryVariables } from '~/entities/customReports/gql/queries/customReports.graphql';
import { RoleBadges } from '~/entities/roles';

import { Tile, TileProps } from '~/features/layouts';

import { CustomReportTileMenu } from '../CustomReportTileMenu';

interface Props extends Partial<TileProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render
   */
  customReport: CustomReportFragment | SkeletonPlaceholder;
  /**
   * Gql custom reports query variables
   */
  queryVariables: CustomReportsQueryVariables;
}

export const CustomReportTile: React.FC<Props> = ({
  className,
  customReport,
  queryVariables,

  ...tileProps
}) => {
  return (
    <Tile
      {...{
        className,
        title: customReport.name,
        description: customReport.blueprint?.name,
        linkProps: {
          to: '/$companyId/user/analytics/custom-reports',
          search: R.identity,
          hash: customReport.id,
          resetScroll: false,
          replace: true,
        },
        bottomContent: <RoleBadges roles={customReport.roles} />,
        renderContextMenuButton: contextMenuButtonProps =>
          !isSkeletonPlaceholder(customReport) && (
            <CustomReportTileMenu
              {...{
                customReport,
                queryVariables,
                ...contextMenuButtonProps,
              }}
            />
          ),
        ...tileProps,
      }}
    />
  );
};
