import React, { ReactNode } from 'react';

import clsx from 'clsx';
import { UseComboboxReturnValue } from 'downshift';

import { AsyncList } from '~/shared/components/AsyncList';
import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from '~/shared/components/Button';
import { Checkbox } from '~/shared/components/Checkbox';
import { Loader } from '~/shared/components/Loader';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { ColorShades, getColorTokenValue } from '~/shared/helpers/color';
import {
  defaultGetItemDescription,
  defaultGetItemText,
  defaultGetItemValue,
} from '~/shared/helpers/itemProps';
import { useScrollIntoViewRef } from '~/shared/hooks/useScrollIntoViewRef';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';

import { SelectItem, SelectProps, SelectVariants } from '../../types';
import styles from './index.module.scss';

type InheritedSelectProps =
  | 'name'
  | 'variant'
  | 'isMulti'
  | 'noItemsFoundMessage'
  | 'noItemsMessage'
  | 'getItemValue'
  | 'getItemDescription'
  | 'getItemText'
  | 'getItemColorVariant'
  | 'renderItemText'
  | 'listActionLabel'
  | 'onListActionPress'
  | 'asyncProps'
  | 'popoverWidth';

type InheritedComboboxProps =
  | 'isOpen'
  | 'highlightedIndex'
  | 'getItemProps'
  | 'closeMenu';

type Props<I extends SelectItem> = Pick<SelectProps<I>, InheritedSelectProps> &
  Pick<UseComboboxReturnValue<I>, InheritedComboboxProps> & {
    /**
     * Items to render in the list
     */
    filteredItems: I[];
    /**
     * Items with selected state
     */
    selectedItems: I[];
    /**
     * Width of the element, to which items list is attached
     */
    controlWidth: number;
    /**
     * If true, search is in progress, so we should change noItemsMessage to noItemsFoundMessage
     */
    isSearchActive: boolean;
    /**
     * A search input element for the items list for popupSearch variant
     */
    inputElement?: ReactNode;
  };

export const SelectItemsList = <I extends SelectItem>({
  filteredItems,
  selectedItems = [],
  isSearchActive = false,

  inputElement,

  popoverWidth,
  controlWidth,

  isOpen,
  highlightedIndex,
  getItemProps,
  closeMenu,

  name,

  variant,
  isMulti,

  noItemsFoundMessage,
  noItemsMessage,

  getItemValue = defaultGetItemValue,
  getItemText = defaultGetItemText,
  renderItemText,
  getItemDescription = defaultGetItemDescription,
  getItemColorVariant,

  listActionLabel,
  onListActionPress,

  asyncProps,
}: Props<I>) => {
  const isCompact = variant === SelectVariants.compact;
  const isPopupSearch = variant === SelectVariants.popupSearch;

  const selectedItemRef = useScrollIntoViewRef(isOpen);

  const dropdownWidthPx =
    (popoverWidth || controlWidth) - NUMBER_TOKENS.borderWidth1 * 2;

  return (
    <div
      {...{
        className: styles.root,
        // used for modal clickOutside behavior
        'data-is-floating-select': true,
      }}
    >
      {isPopupSearch && (
        <div className={styles.additionalListItem}>{inputElement}</div>
      )}
      <AsyncList<I, false>
        {...{
          wrapperTag: 'ul',
          withInnerRootRef: true,
          className: clsx(
            styles.list,
            isCompact && styles.compactList,
            isMulti && styles.multi
          ),
          style: {
            width: dropdownWidthPx,
          },
          items: filteredItems,
          noItemsMessage: (
            <Typography
              variant={TypographyVariants.bodySmall}
              tag="li"
              className={clsx('text-muted', styles.listItem)}
            >
              {isSearchActive ? noItemsFoundMessage : noItemsMessage}
            </Typography>
          ),
          renderLoader: sentryRef => (
            <li ref={sentryRef} className={styles.listItem}>
              <Loader className={styles.loader} />
            </li>
          ),
          renderItem: (item, index) => {
            const itemValue = getItemValue(item);
            const key = `${itemValue}_${index}`;

            const isSelected = selectedItems.includes(item);
            const isHighlighted = highlightedIndex === index;

            const itemDescription = getItemDescription(item);

            const renderItemContent = renderItemText ?? getItemText;

            const itemColor = getItemColorVariant?.(item);

            return (
              <li
                key={key}
                {...getItemProps({
                  item,
                  ref: isSelected && !isMulti ? selectedItemRef : undefined,
                  className: clsx(styles.interactiveListItem, {
                    [styles.selected]: isSelected,
                    [styles.highlighted]: isHighlighted,
                  }),
                })}
              >
                {isMulti && (
                  <Checkbox
                    {...{
                      name: `${name}_checkbox`,
                      value: isSelected,
                      // Here checkbox is used just for render, all logic is handled by the item itself
                      className: 'pointer-events-none',
                      tabIndex: -1,
                      withFormContext: false,
                    }}
                  />
                )}

                <Typography
                  {...{
                    variant: TypographyVariants.bodySmall,
                    tag: 'div',
                    className: isCompact
                      ? styles.listItemContentWrapper
                      : undefined,
                    style: {
                      background: itemColor
                        ? getColorTokenValue(
                            itemColor,
                            ColorShades.opaqueContainerDefault
                          )
                        : undefined,
                    },
                  }}
                >
                  {renderItemContent(item)}
                </Typography>
                {!!itemDescription && (
                  <Typography
                    {...{
                      tag: 'div',
                      className: 'mt-4 text-muted',
                      variant: TypographyVariants.bodySmall,
                    }}
                  >
                    {itemDescription}
                  </Typography>
                )}
              </li>
            );
          },
          ...asyncProps,
        }}
      />
      {!!listActionLabel && (
        <div className={styles.additionalListItem}>
          <Button
            {...{
              variant: ButtonVariants.secondary,
              size: ButtonSizes.small24,
              onPress: () => {
                onListActionPress?.();
                closeMenu();
              },
            }}
          >
            {listActionLabel}
          </Button>
        </div>
      )}
    </div>
  );
};
