import React from 'react';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { isTmpId } from '~/shared/helpers/string';

import { useArkaNavigation } from '~/services/navigation';

import { VIEW_KINDS_DICT } from '~/entities/blueprints';
import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';
import { RoleBadges } from '~/entities/roles';

import { Tile, TileProps } from '~/features/layouts';

import { BlueprintTileContextMenuButton } from '../BlueprintTileContextMenuButton';

interface Props extends Partial<TileProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Blueprint to render
   */
  blueprint: BlueprintFragment | SkeletonPlaceholder;

  /**
   * Called, when user clicks a button to delete blueprint
   */
  onDelete?: () => void;
}

export const BlueprintTile: React.FC<Props> = ({
  className,
  blueprint,
  onDelete,

  ...tileProps
}) => {
  const { isIntegratorInSelectedCompany: canEdit, urlCompanyId } =
    useArkaNavigation();

  const viewKind = blueprint.viewSettings?.kind;

  return (
    <Tile
      {...{
        className,
        title: blueprint.name,
        description: blueprint.description,
        topBadgeProps: viewKind && {
          children: VIEW_KINDS_DICT[viewKind],
        },
        linkProps: isTmpId(blueprint.id)
          ? {
              to: '/$companyId/user/planned-injections',
            }
          : {
              to: '/$companyId/user/blueprint/$blueprintId',
              params: {
                blueprintId: blueprint.id,
                companyId: urlCompanyId,
              },
            },
        bottomContent: <RoleBadges roles={blueprint.roles} />,
        renderContextMenuButton: contextMenuButtonProps =>
          !isSkeletonPlaceholder(blueprint) &&
          canEdit &&
          !isTmpId(blueprint.id) && (
            <BlueprintTileContextMenuButton
              {...{
                onDelete,
                blueprint,
                ...contextMenuButtonProps,
              }}
            />
          ),
        ...tileProps,
      }}
    />
  );
};
