import { AppLocalStorageKeys } from '~/shared/constants';
import { useLocalStorage } from '~/shared/hooks/useLocalStorage';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';

import { TileSizes } from '../types';

/**
 * Hook for using current tile size based on page name
 */
export const useTileSize = (pageName: string) => {
  const [tileSize, setTileSize] = useLocalStorage<TileSizes>(
    `${AppLocalStorageKeys.tileSize}.${pageName}`,
    TileSizes.medium
  );

  return {
    tileSize,
    setTileSize,
    tilesGridClassName:
      tileSize === TileSizes.medium
        ? contentGridStyles.autoGridTilesMedium
        : contentGridStyles.autoGridTilesLarge,
  };
};
