import React, { ReactNode } from 'react';

import { useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import clsx from 'clsx';

import { Label } from '~/shared/components/Label';
import {
  defaultGetItemDescription,
  defaultGetItemText,
  defaultGetItemValue,
} from '~/shared/helpers/itemProps';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { BaseFieldProps } from '~/shared/types/controls';

import { ChipButton } from './components/ChipButton';
import { RadioButton } from './components/RadioButton';
import { SegmentedTabButton } from './components/SegmentedTabButton';
import styles from './index.module.scss';

/**
 * Possible render variants of the radio group
 */
export enum RadioGroupVariants {
  radio = 'radio',
  verticalRadio = 'verticalRadio',
  segmented = 'segmented',
  chip = 'chip',
}

/**
 * RadioGroup can contain objects or strings as items
 */
type RadioGroupItem = string | Record<string, unknown>;

type RadioGroupValue<I extends RadioGroupItem = RadioGroupItem> = I extends {
  id: string;
}
  ? I['id']
  : string;

interface Props<
  I extends RadioGroupItem = RadioGroupItem,
  Value extends string = RadioGroupValue<I>,
> extends BaseFieldProps<Value> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Array of items that are displayed as radio buttons
   */
  items: I[];
  /**
   * Text representation of an item
   */
  renderItem?: (item: I | undefined) => ReactNode;
  /**
   * Get primitive value from an item
   */
  getItemValue?: (item: I) => string;
  /**
   * Get description text for an item
   */
  getItemDescription?: (item: I) => string;
  /**
   * Possible render variants of the radio group
   */
  variant?: RadioGroupVariants;
}

const radioGroupVariants = {
  [RadioGroupVariants.radio]: RadioButton,
  [RadioGroupVariants.verticalRadio]: RadioButton,
  [RadioGroupVariants.segmented]: SegmentedTabButton,
  [RadioGroupVariants.chip]: ChipButton,
};

const RadioGroupInner = <
  I extends RadioGroupItem = RadioGroupItem,
  Value extends string = RadioGroupValue<I>,
>(
  props: Props<I, Value>,
  forwardedRef: React.Ref<HTMLInputElement>
) => {
  const {
    className,

    name,

    label,
    labelProps,

    items,
    onValueChange,
    variant = RadioGroupVariants.radio,

    renderItem = defaultGetItemText,
    getItemValue = defaultGetItemValue,
    getItemDescription = defaultGetItemDescription,
  } = props;

  const state = useRadioGroupState({
    ...props,
    onChange: newValue => onValueChange?.(newValue as Value),
  });

  const { radioGroupProps } = useRadioGroup(
    {
      ...props,
      'aria-label': name,
      orientation:
        variant === RadioGroupVariants.verticalRadio
          ? 'vertical'
          : 'horizontal',
    },
    state
  );

  const RadioButtonComponent = radioGroupVariants[variant];

  return (
    <div
      {...{
        className: clsx(styles.root, className),
      }}
    >
      {!!label && <Label {...labelProps}>{label}</Label>}
      <div
        {...{
          className: styles[variant],
          ...radioGroupProps,
        }}
      >
        {items.map(item => {
          const itemValue = getItemValue(item);

          return (
            <RadioButtonComponent
              key={itemValue}
              {...{
                ref: forwardedRef,
                value: itemValue,
                radioGroupState: state,
                description: getItemDescription(item),
              }}
            >
              {renderItem(item)}
            </RadioButtonComponent>
          );
        })}
      </div>
    </div>
  );
};

// Workaround for typing generic HOC
type RenderRadioGroup = <I extends RadioGroupItem>(
  props: Props<I>
) => React.ReactElement;

export const RadioGroup = withOptionalFormController<Props, string, string>({
  defaultValue: '',
})(React.forwardRef(RadioGroupInner)) as RenderRadioGroup;
