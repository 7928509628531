import React, { DependencyList, useCallback, useEffect, useRef } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { useResizeObserver } from '@react-aria/utils';
import clsx from 'clsx';

import { LAYOUT_ROOT_ID } from '~/shared/constants';
import { useElementSize } from '~/shared/hooks/useElementSize';
import { useEventListener } from '~/shared/hooks/useEventListener';
import { useIsOverflow } from '~/shared/hooks/useIsOverflow';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import customScrollStyles from '~/styles/modules/customScroll.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

interface UseCustomScrollWrapperProps {
  /**
   * If true, scroll container is automatically scrolled to its scrollWidth, when autoScrollDeps changes
   */
  withAutoScrollToEnd?: boolean;
  /**
   * If passed, used to trigger auto scroll, by default triggers only on mount
   */
  autoScrollDeps?: DependencyList;
  /**
   * If true, doesn't render ScrollSyncPane if no overflow occurs
   * Layout shift may occur, if this is set to true (default - true)
   */
  shouldHideScrollBarsForNoOverflow?: boolean;
  /**
   * If true, renders a .panel for the main scroll wrapper (default - true)
   * TODO remove when we remove tertiary table
   */
  withPanel?: boolean;
}

interface RenderCustomScrollWrapperProps extends React.PropsWithChildren {
  /**
   * className applied to the scroll container element
   */
  className?: string;
  /**
   * className applied to the content scroll wrapper
   */
  contentClassName?: string;
  /**
   * Key prefix for rendering scrollbars, should be different for different contentWidth values
   */
  scrollBarKey: React.Key;
}

const STICKY_TABS_SELECTOR = '[data-is-sticky-tabs="true"]';

const STICKY_ELEMENT_SELECTOR =
  ':not([data-is-expandable-row="true"]) [data-is-sticky-thead="true"]';

const STICKY_ELEMENT_NESTED_SELECTOR =
  '[data-is-expandable-row="true"] [data-is-sticky-thead="true"]';

/**
 * Hook for rendering custom scroll bar at the bottom or at the top of the table
 */
export const useCustomScrollWrapper = ({
  autoScrollDeps = [],
  withAutoScrollToEnd = !!autoScrollDeps.length,
  shouldHideScrollBarsForNoOverflow = true,
  withPanel = true,
}: UseCustomScrollWrapperProps = {}) => {
  const { ref: useIsOverflowRef, isOverflow } = useIsOverflow();

  const [useScrollMeasureRef, { width: contentWidth }] = useElementSize();

  // Scroll to end of container, based on passed deps
  useEffect(() => {
    if (!withAutoScrollToEnd) return;

    // Timeout for ScrollSync to update first to avoid glitch with not synced scrollbar
    setTimeout(() => {
      if (!useIsOverflowRef.current) {
        return;
      }
      useIsOverflowRef.current.scrollTo(
        useIsOverflowRef.current.scrollWidth,
        0
      );
    }, 0);
  }, [withAutoScrollToEnd, ...autoScrollDeps]);

  const scrollHoverContainerRef = useRef<React.ElementRef<'div'>>(null);

  // This scroll handler adds ability
  // to have nested sticky elements in the scroll container,
  // which we can't have with css sticky position,
  const updateStickyContent = useCallback(
    (currentStickyElement: Element, rootStickyElement: Element) => {
      const stickyElementRoot = currentStickyElement?.parentElement;
      const layoutRoot = document.getElementById(LAYOUT_ROOT_ID);

      if (
        !(currentStickyElement instanceof HTMLElement) ||
        !layoutRoot ||
        !stickyElementRoot
      )
        return;

      // We always expect a px value for --sticky-header-top-current, so we convert it to number
      const stickyHeaderTopCurrentPx = parseInt(
        getComputedStyle(layoutRoot).getPropertyValue(
          '--sticky-header-top-current'
        ),
        10
      );

      const stickyTabsHeightPx =
        document.querySelector(STICKY_TABS_SELECTOR)?.clientHeight ?? 0;

      let scrollThreshold =
        stickyElementRoot.getBoundingClientRect().y -
        stickyHeaderTopCurrentPx -
        stickyTabsHeightPx;

      if (currentStickyElement !== rootStickyElement) {
        scrollThreshold -=
          rootStickyElement.clientHeight - NUMBER_TOKENS.borderWidth1;
      }
      if (
        scrollThreshold >= 0 ||
        scrollThreshold -
          currentStickyElement.getBoundingClientRect().height +
          stickyElementRoot.scrollHeight <
          0
      ) {
        // eslint-disable-next-line no-param-reassign -- we should mutate passed currentStickyElement to archive sticky behaviour
        currentStickyElement.style.transform = 'none';
      } else if (scrollThreshold < 0) {
        // eslint-disable-next-line no-param-reassign
        currentStickyElement.style.transform = `translateY(${-scrollThreshold}px)`;
      }
    },
    []
  );

  const updateAllStickyContents = useCallback(() => {
    if (!scrollHoverContainerRef.current) {
      return;
    }

    const rootStickyElement = scrollHoverContainerRef.current.querySelector(
      STICKY_ELEMENT_SELECTOR
    );

    const nestedStickyElements = Array.from(
      scrollHoverContainerRef.current.querySelectorAll(
        STICKY_ELEMENT_NESTED_SELECTOR
      )
    );
    if (!rootStickyElement) return;

    [rootStickyElement, ...nestedStickyElements].forEach(stickyElement => {
      updateStickyContent(stickyElement, rootStickyElement);
    });
  }, [updateStickyContent]);

  useEventListener('scroll', updateAllStickyContents, undefined, {
    passive: true,
  });

  useResizeObserver({
    ref: scrollHoverContainerRef,
    onResize: updateAllStickyContents,
  });

  const shouldRenderScrollbars =
    !shouldHideScrollBarsForNoOverflow || isOverflow;

  const renderCustomScrollWrapper = ({
    className,
    contentClassName,
    scrollBarKey,
    children,
  }: RenderCustomScrollWrapperProps) => {
    return (
      <ScrollSync key={scrollBarKey}>
        <div
          ref={scrollHoverContainerRef}
          className={clsx(
            className,
            customScrollStyles.customScrollHoverContainer
          )}
        >
          <ScrollSyncPane innerRef={useIsOverflowRef}>
            <div
              className={clsx(
                'overflow-auto hidden-scrollbar',
                customScrollStyles.customScrollBaseContainer,
                contentClassName,
                withPanel && panelStyles.panel
              )}
            >
              {children}
            </div>
          </ScrollSyncPane>
          {shouldRenderScrollbars && (
            <ScrollSyncPane>
              <div
                key={`${scrollBarKey}__bottom`}
                className={clsx(
                  'full-width pb-4',
                  customScrollStyles.customScrollBaseContainer,
                  customScrollStyles.customScrollStickyContainer,
                  isOverflow ? 'overflow-auto' : 'overflow-hidden'
                )}
              >
                <div style={{ width: contentWidth }} />
              </div>
            </ScrollSyncPane>
          )}
        </div>
      </ScrollSync>
    );
  };

  return {
    useScrollMeasureRef,
    renderCustomScrollWrapper,
  };
};
