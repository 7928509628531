import React, { useCallback, useEffect, useRef, useState } from 'react';

import { usePress } from '@react-aria/interactions';
import { useId, useResizeObserver } from '@react-aria/utils';

import { useControllableState } from '~/shared/hooks/useControllableState';
import { usePrevious } from '~/shared/hooks/usePrevious';

/**
 * Props of any accordion
 */
export interface UseAccordionProps extends React.PropsWithChildren {
  /**
   * If true, disables accordion controls
   */
  isDisabled?: boolean;
  /**
   * Open state
   */
  isOpen?: boolean;
  /**
   * Default open state
   */
  defaultIsOpen?: boolean;
  /**
   * Unmount content after closing
   */
  shouldUnmountClosedChildren?: boolean;
  /**
   * Called, when accordion open state changes
   */
  onToggle?: (isOpen: boolean) => void;
}

export const ACCORDION_COLLAPSE_DURATION_MS = 300;

/**
 * Hook for reusing accordion pattern
 */
export const useAccordion = ({
  isDisabled = false,
  isOpen: isOpenProp,
  defaultIsOpen = isOpenProp,
  shouldUnmountClosedChildren = true,
  onToggle,
}: UseAccordionProps = {}) => {
  const accordionId = useId();

  const childrenRef = useRef<HTMLDivElement>(null);
  const childrenContainerRef = useRef<HTMLDivElement>(null);

  const [shouldRenderContent, setShouldRenderContent] = useState(
    shouldUnmountClosedChildren ? defaultIsOpen : true
  );
  const [isAnimating, setIsAnimating] = useState(false);

  // Enable content rendering if shouldUnmountClosedChildren has changed and is true
  useEffect(() => {
    if (!shouldUnmountClosedChildren && !shouldRenderContent) {
      setShouldRenderContent(true);
    }
  }, [shouldUnmountClosedChildren]);

  const renderContentTimeoutRef = useRef<NodeJS.Timeout>();
  const toggle = (newIsOpen: boolean) => {
    if (shouldUnmountClosedChildren) {
      clearTimeout(renderContentTimeoutRef.current);
      setIsAnimating(true);
      if (newIsOpen) {
        setShouldRenderContent(true);
      } else {
        renderContentTimeoutRef.current = setTimeout(() => {
          setShouldRenderContent(false);
        }, ACCORDION_COLLAPSE_DURATION_MS);
      }
    }
  };

  const [isOpen, setIsOpen] = useControllableState(
    isOpenProp,
    toggle,
    defaultIsOpen
  );

  const prevIsOpen = usePrevious(isOpen);
  // Actualize shouldRenderContent if isOpenProp changes
  useEffect(() => {
    if (isOpenProp !== undefined && isOpenProp !== prevIsOpen) {
      setIsOpen(isOpenProp);
    }
  }, [isOpenProp]);

  const { pressProps } = usePress({
    isDisabled,
    onPress: () => {
      setIsOpen(currentIsOpen => !currentIsOpen);

      setTimeout(() => {
        onToggle?.(!isOpen);
      }, 0);
    },
  });

  const [childrenScrollHeight, setChildrenScrollHeight] = useState(
    defaultIsOpen ? 'auto' : (childrenRef.current?.scrollHeight ?? 0)
  );

  // Update children max height on resize
  useResizeObserver({
    ref: childrenContainerRef,
    onResize: useCallback(() => {
      const setHeight = () => {
        if (childrenRef.current?.scrollHeight) {
          setChildrenScrollHeight(childrenRef.current.scrollHeight);
        }
      };
      setHeight();
      setTimeout(setHeight, ACCORDION_COLLAPSE_DURATION_MS);
    }, []),
  });

  const childrenHeight = isOpen ? childrenScrollHeight : 0;

  const openableHeaderProps = {
    id: `${accordionId}-header`,
    ...pressProps,
  };

  const openButtonProps = {
    tabIndex: 0,
    id: accordionId,
    role: 'button',
    ...pressProps,
  };

  const childrenWrapperProps: React.ComponentProps<'div'> & {
    'data-is-accordion-animating': boolean;
  } = {
    ref: childrenRef,
    style: {
      maxHeight: childrenHeight,
      overflowClipMargin: isOpen ? undefined : '0px',
    },
    onTransitionEnd: e => {
      if (e.propertyName === 'max-height') {
        setIsAnimating(false);
      }
    },
    role: 'region',
    'aria-labelledby': accordionId,
    id: `${accordionId}-panel`,
    'data-is-accordion-animating': isAnimating,
  };

  return {
    isOpen,
    setIsOpen,
    shouldRenderContent,

    childrenContainerRef,

    openableHeaderProps,
    openButtonProps,
    childrenWrapperProps,
  };
};
