import {
  CustomMilkingReportDataSource,
  CustomMilkingReportXAxisMode,
} from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
  PartialSelectProps,
  PartialSelectPropsWithName,
  SelectItemWithId,
} from '~/shared/components/Select';

import { CUSTOM_MILKING_REPORT_X_AXIS_MODES_DICT } from '../../../../constants';

const useCustomMilkingReportXAxisModeSelectInternal = makeUseEnumSelect(
  CustomMilkingReportXAxisMode,
  enumValue =>
    CUSTOM_MILKING_REPORT_X_AXIS_MODES_DICT[
      enumValue as CustomMilkingReportXAxisMode
    ]
);

type XAxisModeSelectItem = SelectItemWithId<
  keyof typeof CustomMilkingReportXAxisMode
>;

type UseCustomMilkingReportXAxisModeSelectProps =
  PartialSelectPropsWithName<XAxisModeSelectItem> & {
    /**
     * Data source, used inside the report. Available items are different for different sources
     */
    dataSource: CustomMilkingReportDataSource;
  };

const useCustomMilkingReportXAxisModeSelect = ({
  dataSource,
  ...selectProps
}: UseCustomMilkingReportXAxisModeSelectProps) => {
  const isDataSourceParlors =
    dataSource === CustomMilkingReportDataSource.MilkingParlors;

  const {
    renderSelectElement: renderXAxisModeSelectElement,
    items: xAxisModeItemsAll,
    ...customMilkingReportXAxisModeSelectSelectInternalResult
  } = useCustomMilkingReportXAxisModeSelectInternal(selectProps);

  const xAxisModeItemsActual = xAxisModeItemsAll.filter(
    item =>
      !isDataSourceParlors ||
      (item.id as CustomMilkingReportXAxisMode) !==
        CustomMilkingReportXAxisMode.TestMilkingNumber
  );

  return {
    renderSelectElement: (
      selectElementProps?: PartialSelectProps<XAxisModeSelectItem>
    ) =>
      renderXAxisModeSelectElement({
        items: xAxisModeItemsActual,
        ...selectElementProps,
      }),
    items: xAxisModeItemsActual,
    ...customMilkingReportXAxisModeSelectSelectInternalResult,
  };
};

export const CustomMilkingReportXAxisModeSelect = makeSelectComponentFromHook(
  useCustomMilkingReportXAxisModeSelect
);
