import React from 'react';

import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Skeleton, useSkeletonContext } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { ColorShades, getColorTokenValue } from '~/shared/helpers/color';

import {
  ColorVariants,
  SizeVariants,
} from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

/**
 * Possible badge size variants
 */
export enum BadgeSizes {
  large24 = 'large24',
  medium20 = 'medium20',
  small16 = 'small16',
}

const SKELETON_HEIGHTS_PX: Record<BadgeSizes, number> = {
  [BadgeSizes.large24]: 24,
  [BadgeSizes.medium20]: 20,
  [BadgeSizes.small16]: 16,
};

export interface BadgeProps extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Size variant of the badge
   */
  size?: BadgeSizes;
  /**
   * If true, uses full rounded corners, design system uses this variant for counters
   */
  isPill?: boolean;
  /**
   * Icon variant to display with text
   */
  iconVariant?: IconVariants;

  /**
   * Badge color theme
   */
  color?: ColorVariants;
}

export const Badge = React.forwardRef<HTMLInputElement, BadgeProps>(
  (
    {
      className,
      size = BadgeSizes.large24,
      isPill,
      iconVariant,
      color = ColorVariants.neutral,
      children,
    },
    ref
  ) => {
    const { renderWithSkeleton } = useSkeletonContext();

    return renderWithSkeleton(
      <Skeleton.Block height={SKELETON_HEIGHTS_PX[size]} />,
      <span
        {...{
          className: clsx(
            styles.root,
            isPill && styles.pill,
            styles[size],
            !isPill && 'ellipsis',
            className
          ),
          style: {
            color: getColorTokenValue(color, ColorShades.hover),
            backgroundColor: getColorTokenValue(
              color,
              ColorShades.containerDefault
            ),
          },
          title: !isPill && typeof children === 'string' ? children : undefined,
          ref,
        }}
      >
        {iconVariant && (
          <Icon
            {...{
              variant: iconVariant,
              size:
                size === BadgeSizes.large24
                  ? SizeVariants.size16
                  : SizeVariants.size12,
            }}
          />
        )}
        <Typography
          {...{
            className: clsx(styles.text, !isPill && 'ellipsis'),
            variant:
              size === BadgeSizes.small16
                ? TypographyVariants.descriptionLargeStrong
                : TypographyVariants.bodySmallStrong,
          }}
        >
          {children}
        </Typography>
      </span>
    );
  }
);
