import React, { useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';

import { FieldFeedback } from '~/shared/components/FieldFeedback';
import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { Label } from '~/shared/components/Label';
import { Loader } from '~/shared/components/Loader';
import { Popover } from '~/shared/components/Popover';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { ColorShades, getColorTokenValue } from '~/shared/helpers/color';
import {
  defaultGetItemDescription,
  defaultGetItemText,
  defaultGetItemValue,
} from '~/shared/helpers/itemProps';
import { mergeHandlers, mergeRefs } from '~/shared/helpers/mergeProps';
import { normalizeValuesToArray } from '~/shared/helpers/normalize';
import { joinJsxArray } from '~/shared/helpers/render';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { useElementSize } from '~/shared/hooks/useElementSize';
import { usePrevious } from '~/shared/hooks/usePrevious';

import { SelectItemsList } from './components/SelectItemsList';
import styles from './index.module.scss';
import {
  RenderSelect,
  SelectItem,
  SelectProps,
  SelectThemes,
  SelectVariants,
} from './types';

const SelectInternal = <I extends SelectItem>(
  {
    name,
    className,
    isDisabled,
    isRequired,
    placeholder = 'Выберите значение',

    items,
    inputRef,
    shouldOpenOnArrows = true,

    theme = SelectThemes.dark,
    variant = SelectVariants.full,
    isClearable = false,
    listActionLabel,
    onListActionPress,
    isOptimistic = false,
    loadingMessage,
    popoverWidth,

    popoverProps,

    // Can't use default here but undefined is falsy anyways - https://github.com/microsoft/TypeScript/issues/50139
    isMulti,
    hasError,

    rawValue,
    value: valueProp,
    onValueChange,
    defaultValue,

    getItemValue = defaultGetItemValue,
    getItemText = defaultGetItemText,
    renderSelectedItem = getItemText,
    renderItemText,
    getItemDescription = defaultGetItemDescription,
    getItemColorVariant,
    noItemsMessage = 'Нет элементов',
    noItemsFoundMessage = 'Нет совпадений',
    renderToggleButton,

    search: searchProp,
    defaultSearch = '',
    withSearch = true,
    onSearchChange,
    isItemMatchingSearch = (item, search) => {
      let itemText = getItemText(item).toLowerCase();

      const description = getItemDescription(item)?.toLowerCase();

      if (description) {
        itemText = `${itemText} ${description}`;
      }
      return itemText.includes(search.toLowerCase());
    },

    onPaste,
    onKeyDown,

    label,
    labelProps,
    feedback,
    feedbackProps,

    asyncProps,
  }: SelectProps<I>,
  ref: React.Ref<HTMLInputElement>
) => {
  const isCompact = variant === SelectVariants.compact;
  const isPopupSearch = variant === SelectVariants.popupSearch;

  // Search items
  const [search, setSearch] = useControllableState(
    searchProp,
    onSearchChange,
    defaultSearch
  );

  const filteredItems = useMemo(
    () =>
      withSearch
        ? items.filter(item => isItemMatchingSearch(item, search))
        : items,
    [items, search]
  );

  // Normalize values and prepare state
  let value = valueProp;
  if (rawValue !== undefined) {
    value = isMulti
      ? items.filter(i => rawValue.includes(getItemValue(i)))
      : items.find(i => getItemValue(i) === rawValue);
  }
  const valueAsArray = normalizeValuesToArray(value);
  const defaultValueAsArray = normalizeValuesToArray(defaultValue) ?? [];

  const [selectedItemsState, setSelectedItems] = useControllableState<I[]>(
    valueAsArray,
    newValues => {
      if (isMulti) {
        onValueChange?.(newValues);
      } else {
        onValueChange?.(newValues[0] ?? null);
      }
    },
    defaultValueAsArray
  );

  // Special handling of null item value
  let selectedItems = selectedItemsState;
  const itemWithUndefinedValue = filteredItems.find(
    item => getItemValue(item) === null
  );
  if (!selectedItemsState.length && itemWithUndefinedValue) {
    selectedItems = [itemWithUndefinedValue];
  }

  // Handle selection behavior
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
  } = useMultipleSelection({
    selectedItems,
    onSelectedItemsChange: ({ selectedItems: newSelectedItems }) => {
      setSelectedItems(newSelectedItems ?? []);
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        // We don't need focus on active items
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: state.activeIndex,
          };

        default:
          return changes;
      }
    },
  });

  const internalInputRef = useRef<HTMLInputElement>(null);

  // Handle combobox behavior
  const {
    isOpen,
    openMenu,
    closeMenu,
    highlightedIndex,
    selectItem,
    getItemProps,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getToggleButtonProps,
  } = useCombobox<I | null>({
    id: name,
    itemToString: getItemText,
    items: filteredItems,
    inputValue: search,
    onStateChange: ({ type, selectedItem }) => {
      // We need to restore focus on input to remain accessible, it's lost cause of popup
      if (
        type !== useCombobox.stateChangeTypes.InputKeyDownEnter &&
        type !== useCombobox.stateChangeTypes.ItemClick
      ) {
        return;
      }

      setTimeout(() => {
        internalInputRef.current?.focus();
      }, 0);

      if (selectedItem) {
        if (!isMulti) {
          setSearch('');
        }
        if (selectedItems.includes(selectedItem)) {
          if (isMulti) {
            removeSelectedItem(selectedItem);
          } else if (isClearable) {
            setSelectedItems([]);
          }
        } else if (isMulti) {
          addSelectedItem(selectedItem);
        } else {
          setSelectedItems([selectedItem]);
        }
      }
      selectItem(null);
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        // override default behavior to satisfy popup
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen:
              (isPopupSearch && state.isOpen) ||
              (!isDisabled && !loadingMessage && changes.isOpen),
          };

        case useCombobox.stateChangeTypes.InputBlur:
          return state;

        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown: {
          if (state.isOpen) {
            return changes;
          }
          return {
            ...changes,
            isOpen: shouldOpenOnArrows && changes.isOpen,
          };
        }

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            // Don't close multiple select to continue selection
            isOpen: isMulti,
          };

        case useCombobox.stateChangeTypes.ToggleButtonClick:
          return {
            ...changes,
            isOpen: !state.isOpen && !isDisabled && !loadingMessage,
            selectedItem: undefined,
          };

        default:
          return changes;
      }
    },
  });

  const [controlSizeRef, { width: controlWidth }] = useElementSize();

  const isSearchActive = !!search;

  const isInputInPopup = isPopupSearch && isOpen;

  const hasSelectedItems = !!selectedItems.length;

  const selectedItemElement = (
    <span className={clsx('ellipsis', isOptimistic && 'text-muted')}>
      {!!loadingMessage && (
        <Typography
          variant={TypographyVariants.bodyMedium}
          className="text-soft"
        >
          {loadingMessage}
        </Typography>
      )}
      {(isCompact || !isSearchActive) &&
        joinJsxArray(
          selectedItems.map((selectedItem, index) => {
            return (
              <Typography
                key={`${getItemValue(selectedItem)}_${index}`}
                {...{
                  variant: TypographyVariants.bodySmall,
                  ...getSelectedItemProps?.({
                    selectedItem,
                    index,
                  }),
                  tabIndex: -1,
                }}
              >
                {renderSelectedItem(selectedItem)}
              </Typography>
            );
          })
        )}
    </span>
  );

  // Blur is complicated, cause we use portal for items, we can't use default event,
  // so we handle it twice: with click and with keyboard
  const handleBlur = () => {
    closeMenu();
    setSearch('');
  };

  // Special case to not show focus state after optimistic update
  const [wasUpdated, setWasUpdated] = useState(false);

  // Set updated state after optimistic update, so we don't show focus state
  const prevIsOptimistic = usePrevious(isOptimistic);
  useEffect(() => {
    if (prevIsOptimistic === true && !isOptimistic) {
      setWasUpdated(true);
    }
  }, [prevIsOptimistic, isOptimistic]);

  const inputProps = getInputProps({
    autoComplete: 'off',
    'aria-autocomplete': 'none',
    ...getDropdownProps({
      ref: mergeRefs(ref, inputRef, internalInputRef),
      preventKeyAction: isOpen,
      onPaste: onPaste
        ? mergeHandlers(() => {
            closeMenu();
          }, onPaste)
        : undefined,
      onKeyDown: e => onKeyDown?.(e, { isOpen, openMenu, closeMenu }),
    }),
    autoFocus: isInputInPopup,
    onFocus: () => {
      // Remove updated state on next focus
      setWasUpdated(false);
    },
    onKeyDown: e => {
      onKeyDown?.(e, { isOpen, openMenu, closeMenu });

      if (loadingMessage) {
        e.preventDefault();
      }
      // if Tab is pressed blur the dropdown field
      if (e.key === 'Tab') {
        handleBlur();
      }
    },
    // Cursor jump workaround
    // https://github.com/downshift-js/downshift/pull/1576
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value ?? '');
    },
    tabIndex: isPopupSearch && !isOpen ? -1 : undefined,
  });

  const popoverId = `${inputProps.id}-popover-trigger`;

  const selectedItemColor = hasSelectedItems
    ? getItemColorVariant?.(selectedItems[0])
    : undefined;

  const shouldShowLoaderIcon = asyncProps?.isLoading && !filteredItems.length;

  const shouldRenderPlaceholder =
    isInputInPopup || (!hasSelectedItems && !loadingMessage);

  const inputElement = (
    <Input
      key="selectSearch"
      {...{
        name: `${name}_input`,
        withFormContext: false,
        variant: isInputInPopup ? InputVariants.search : undefined,
        theme: isInputInPopup ? InputThemes.dark : InputThemes.unstyled,
        isDisabled: isDisabled || !withSearch,
        placeholder: shouldRenderPlaceholder ? placeholder : undefined,
        className: clsx({
          [styles.hiddenInput]: isCompact || (isPopupSearch && !isInputInPopup),
          [styles.input]: !isCompact && !isInputInPopup,
        }),
        htmlInputProps: inputProps,
      }}
    />
  );

  // We have a special case of transition to popupSearch select,
  // where we should disable background color transition to avoid flickering
  const prevVariant = usePrevious(variant);
  const isChangingVariant = prevVariant && variant !== prevVariant;

  return (
    <div
      {...{
        className: clsx(styles.root, className),
        'data-popover-trigger-id': popoverId,
      }}
    >
      {!!label && theme !== SelectThemes.basic && (
        <Label
          {...{
            isRequired,
            ...getLabelProps(labelProps),
          }}
        >
          {label}
        </Label>
      )}
      <Typography
        {...{
          variant: TypographyVariants.bodySmall,
          tag: 'div',
          ref: controlSizeRef,
          className: clsx(styles[theme], styles[variant], {
            [styles.disabled]: isDisabled,
            [styles.error]: hasError,
            [styles.open]: isOpen,
            [styles.optimistic]: isOptimistic,
            [styles.wasUpdated]: wasUpdated,
            [styles.noTransition]: isChangingVariant,
          }),
          style: selectedItemColor
            ? ({
                '--select-optimistic-color': getColorTokenValue(
                  selectedItemColor,
                  ColorShades.opaqueContainerSoft
                ),
                '--select-default-color': getColorTokenValue(
                  selectedItemColor,
                  ColorShades.opaqueContainerDefault
                ),
                '--select-hover-color': getColorTokenValue(
                  selectedItemColor,
                  ColorShades.opaqueContainerHover
                ),
                '--select-active-color': getColorTokenValue(
                  selectedItemColor,
                  ColorShades.opaqueContainerActive
                ),
              } as React.CSSProperties)
            : {},
        }}
      >
        {!isInputInPopup && inputElement}
        {!isPopupSearch && (
          <div className={styles.content}>
            {selectedItemElement}
            <div className={styles.buttons}>
              {isClearable && hasSelectedItems && !isOpen && !isDisabled && (
                <Icon
                  {...{
                    variant: IconVariants.xClose,
                    onPress: () => {
                      setSearch('');
                      setSelectedItems([]);
                      selectItem(null);
                    },
                  }}
                />
              )}
              {!loadingMessage && !shouldShowLoaderIcon && (
                <Icon
                  {...{
                    // In compact mode toggle button is messing with correct blurring of the select after click
                    ...(isCompact ? {} : getToggleButtonProps()),
                    disabled: isDisabled,
                    variant: IconVariants.chevronDown,
                    rotate: isOpen ? RotateVariants.down : RotateVariants.up,
                  }}
                />
              )}
              {(!!loadingMessage || shouldShowLoaderIcon) && <Loader />}
            </div>
          </div>
        )}
        <Popover
          {...{
            popoverId,
            isOpen,
            placement: 'bottom-start',
            onIsOpenChange: newIsOpen => {
              if (!newIsOpen) {
                handleBlur();
              }
            },
            ...popoverProps,
            renderContent: () => (
              <SelectItemsList
                {...{
                  filteredItems,
                  selectedItems,
                  isSearchActive,

                  inputElement,

                  popoverWidth,
                  controlWidth,

                  isOpen,
                  highlightedIndex,
                  getItemProps,
                  closeMenu,

                  name,

                  variant,
                  isMulti,

                  noItemsFoundMessage,
                  noItemsMessage,

                  getItemValue,
                  getItemText,
                  renderItemText,
                  getItemDescription,
                  getItemColorVariant,

                  listActionLabel,
                  onListActionPress,

                  asyncProps,
                }}
              />
            ),
          }}
        >
          <ul className={styles.listReference} {...getMenuProps()}>
            {isPopupSearch &&
              renderToggleButton?.({
                isOpen,
                openMenu,
                closeMenu,
                getToggleButtonProps,
              })}
          </ul>
        </Popover>
      </Typography>
      {variant === SelectVariants.withItemsList && hasSelectedItems && (
        <div className="grid gap-8 mt-4">
          {selectedItems.map((selectedItem, index) => {
            return (
              <div
                key={`${getItemValue(selectedItem)}_${index}`}
                {...{
                  className: styles.selectedItem,
                }}
              >
                <Typography variant={TypographyVariants.bodySmall}>
                  {getItemText(selectedItem)}
                </Typography>

                <Icon
                  {...{
                    variant: IconVariants.xClose,
                    className: 'text-accent',
                    onPress: () => removeSelectedItem(selectedItem),
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
      {!!feedback && <FieldFeedback content={feedback} {...feedbackProps} />}
    </div>
  );
};

export const Select = withOptionalFormController<
  SelectProps<any>,
  any,
  string | string[]
>({
  defaultValue: ({ isMulti }: SelectProps<any>) => (isMulti ? [] : ''),
  convertValueFromComponentToForm: (
    value,
    { isMulti, getItemValue = defaultGetItemValue }
  ) => {
    if (isMulti) {
      return value.map(getItemValue);
    }
    return value ? getItemValue(value) : null;
  },
  convertValueFromFormToComponent: (
    value,
    { items, getItemValue = defaultGetItemValue }
  ) => {
    const getValueForItem = (val: string) =>
      items.find(item => getItemValue(item) === val);

    if (Array.isArray(value)) {
      return value.map(getValueForItem).filter(Boolean);
    }
    return getValueForItem(value);
  },
})(
  React.forwardRef<HTMLInputElement, SelectProps<any>>(SelectInternal)
) as RenderSelect;

export * from './helpers';
export * from './types';
