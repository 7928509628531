import React from 'react';

import clsx from 'clsx';

import { Badge, BadgeSizes } from '~/shared/components/Badge';
import { IconVariants } from '~/shared/components/Icon';
import { useSkeletonContext } from '~/shared/components/Skeleton';
import { Tooltip } from '~/shared/components/Tooltip';

import { RoleFragment } from '~/services/auth/gql/fragments/role.graphql';

import { ROLE_COLOR_CONFIGS } from '~/entities/roles';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Blueprint roles of CustomReportFragment
   */
  roles?: RoleFragment[];
}

export const RoleBadges: React.FC<Props> = ({ className, roles }) => {
  const { renderWithoutSkeleton } = useSkeletonContext();

  if (!roles?.length) {
    return null;
  }

  const [firstRole, ...otherRoles] = roles;

  return renderWithoutSkeleton(
    <div className={clsx('flex gap-8 items-center full-width', className)}>
      <Badge
        {...{
          iconVariant: IconVariants.dot,
          color: ROLE_COLOR_CONFIGS[firstRole.color].colorVariant,
          size: BadgeSizes.medium20,
        }}
      >
        {firstRole.name}
      </Badge>
      {!!otherRoles.length && (
        <Tooltip
          {...{
            content: (
              <div className="grid gap-8">
                {otherRoles.map(role => (
                  <Badge
                    key={role.id}
                    {...{
                      iconVariant: IconVariants.dot,
                      color: ROLE_COLOR_CONFIGS[role.color].colorVariant,
                      size: BadgeSizes.medium20,
                    }}
                  >
                    {role.name}
                  </Badge>
                ))}
              </div>
            ),
          }}
        >
          <Badge isPill>{`+${otherRoles.length}`}</Badge>
        </Tooltip>
      )}
    </div>
  );
};
