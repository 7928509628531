import React from 'react';

import {
  ContextMenuButton,
  PartialContextMenuButtonProps,
} from '~/shared/components/ContextMenuButton';
import { MenuItemType, MenuItemVariants } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useConfirm } from '~/services/modals';
import { useArkaNavigation } from '~/services/navigation';

import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';

interface Props extends PartialContextMenuButtonProps {
  blueprint: BlueprintFragment;
  className?: string;
  onDelete?: () => void;
}

export const BlueprintTileContextMenuButton: React.FC<Props> = ({
  blueprint,
  className,
  onDelete,
  ...contextButtonProps
}) => {
  const confirmDelete = useConfirm();

  const { navigate, urlCompanyId } = useArkaNavigation();

  return (
    <ContextMenuButton<MenuItemType>
      {...{
        className,
        tooltip: 'Действия с командой',
        items: [
          {
            content: 'Запустить',
            onPress: () =>
              navigate({
                to: '/$companyId/user/blueprint/$blueprintId',
                params: {
                  blueprintId: blueprint.id,
                  companyId: urlCompanyId,
                },
              }),
          },
          {
            variant: MenuItemVariants.delimiter,
          },
          {
            content: 'Настройки',
            onPress: () =>
              navigate({
                to: '/$companyId/integrator/blueprints/$blueprintId',
                params: {
                  blueprintId: blueprint.id,
                  companyId: urlCompanyId,
                },
              }),
          },
          {
            content: 'Редактировать',
            onPress: () =>
              navigate({
                to: '/$companyId/integrator/blueprints/edit/$blueprintId',
                params: {
                  blueprintId: blueprint.id,
                  companyId: urlCompanyId,
                },
              }),
          },
          onDelete
            ? {
                variant: MenuItemVariants.destructive,
                content: 'Удалить',
                onPress: async () => {
                  const isConfirmed = await confirmDelete({
                    title: 'Удаление команды',
                    message: (
                      <div className="grid gap-12">
                        <Typography
                          tag="p"
                          variant={TypographyVariants.bodySmall}
                        >
                          Вы хотите удалить команду{' '}
                          <Typography
                            variant={TypographyVariants.bodySmallStrong}
                          >
                            {blueprint.name}
                          </Typography>
                          ?
                        </Typography>
                        <Typography
                          tag="p"
                          variant={TypographyVariants.bodySmall}
                        >
                          Это действие невозможно отменить.
                        </Typography>
                      </div>
                    ),
                    isDelete: true,
                  });
                  if (isConfirmed) {
                    onDelete();
                  }
                },
              }
            : null,
        ].filter(Boolean),
        ...contextButtonProps,
      }}
    />
  );
};
