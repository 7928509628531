import React, { ReactNode, useRef } from 'react';

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingFocusManagerProps,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  UseListNavigationProps,
  useRole,
} from '@floating-ui/react';
import clsx from 'clsx';

import { mergeProps, mergeRefs } from '~/shared/helpers/mergeProps';
import { useControllableState } from '~/shared/hooks/useControllableState';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';

import styles from './index.module.scss';

interface BasePopoverProps extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Render prop for content of the opened popover
   */
  renderContent?: (listProps: {
    getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
    listRef: React.MutableRefObject<Array<HTMLElement | null>>;
  }) => ReactNode;

  /**
   * Preferred popover placement
   */
  placement?: Placement;
  /**
   * Optional id for detecting popover trigger by data-popover-trigger-id attribute
   */
  popoverId?: string;

  /**
   * Props for focus management
   */
  floatingFocusManagerProps?: Partial<FloatingFocusManagerProps>;

  /**
   * Is popover opened
   */
  isOpen?: boolean;
  /**
   * Default isOpen value
   */
  defaultIsOpen?: boolean;
  /**
   * Called, when isOpen state is changed internally by user interactions
   */
  onIsOpenChange?: (newIsOpen: boolean) => void;
  /**
   * If true, than popover will be closed, when its content is clicked
   */
  shouldCloseOnContentClick?: boolean;
  /**
   * If true, popover is disabled to open, (default - false)
   */
  isDisabled?: boolean;
}

interface PopoverWithoutNavigationProps extends BasePopoverProps {
  /**
   * If true, popover handles arrow list navigation
   */
  withListNavigation?: false | undefined;
  /**
   * Props for useListNavigation, only pass this prop with withListNavigation === true
   */
  listNavigationProps?: undefined;
}

interface PopoverWithNavigationProps extends BasePopoverProps {
  /**
   * If true, popover handles arrow list navigation
   */
  withListNavigation: true;
  /**
   * Props for useListNavigation, only pass this prop with withListNavigation === true
   */
  listNavigationProps: Omit<UseListNavigationProps, 'listRef'>;
}

export type PopoverProps =
  | PopoverWithoutNavigationProps
  | PopoverWithNavigationProps;

const DEFAULT_OFFSET_PX = NUMBER_TOKENS.spacing4;

export const Popover: React.FC<PopoverProps> = ({
  className,

  renderContent,

  placement = 'bottom',
  popoverId,

  floatingFocusManagerProps,

  isOpen: isOpenProp,
  onIsOpenChange,
  defaultIsOpen = false,
  shouldCloseOnContentClick = false,
  isDisabled = false,

  withListNavigation,
  listNavigationProps,

  children,
}) => {
  const [isOpen, setIsOpen] = useControllableState(
    isOpenProp,
    onIsOpenChange,
    defaultIsOpen
  );

  const { context: floatingContext, floatingStyles } = useFloating({
    placement,
    open: isOpen,
    strategy: 'fixed',
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(DEFAULT_OFFSET_PX),
      flip({
        crossAxis: placement.includes('-'),
        padding: DEFAULT_OFFSET_PX,
      }),
      shift({ padding: DEFAULT_OFFSET_PX }),
    ],
  });

  const click = useClick(floatingContext, { enabled: !isDisabled });
  const dismiss = useDismiss(floatingContext, {
    enabled: !isDisabled,
    outsidePress: event => {
      const target = event.target as HTMLElement;
      return !target.closest(`[data-popover-trigger-id="${popoverId}"]`);
    },
  });
  const role = useRole(floatingContext, { enabled: !isDisabled });

  const listRef = useRef([]);

  const listNavigation = useListNavigation(floatingContext, {
    enabled: withListNavigation && !isDisabled,
    loop: true,
    listRef,
    activeIndex: null,
    ...listNavigationProps,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, dismiss, role, listNavigation]
  );

  const referenceWithFloatingPropsElement = React.isValidElement(children)
    ? React.cloneElement(
        children,
        getReferenceProps({
          ref: mergeRefs(
            (children as any).ref,
            floatingContext.refs.setReference
          ),
          ...mergeProps(
            !isDisabled && { className: styles.cursorPointer },
            children.props
          ),
        })
      )
    : null;

  return (
    <>
      {referenceWithFloatingPropsElement}
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager
            {...{
              context: floatingContext,
              order: ['reference', 'content'],
              modal: false,
              ...floatingFocusManagerProps,
            }}
          >
            <div
              {...getFloatingProps({
                className: clsx(className, styles.root),
                ref: floatingContext.refs.setFloating,
                style: floatingStyles,
                onClick: () => {
                  if (shouldCloseOnContentClick) {
                    setIsOpen(false);
                  }
                },
              })}
            >
              {renderContent?.({ getItemProps, listRef })}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
