import React from 'react';

import { CustomReportChartKindEnum } from '@graphql-types';
import clsx from 'clsx';

import { Input } from '~/shared/components/Input';
import { makeUseEnumSelect, Select } from '~/shared/components/Select';

import { Form } from '~/services/forms';

import { isCustomReportSettingsValue } from '~/entities/customReports';

import {
  getCustomReportValueConfigKey,
  getValueColumnName,
  mapCustomReportValueKeyToForm,
} from '~/features/customReportLaunch';
import { ReportCardBuilderFormSection } from '~/features/reportCardBuilder';

import contentGriStyles from '~/styles/modules/contentGrid.module.scss';

import {
  CUSTOM_REPORT_CHART_KINDS_DICT,
  CUSTOM_REPORT_CHART_SETTINGS_FORM_ID,
} from '../../constants';
import { YAxisConfigArrayItemCard } from './components/YAxisConfigArrayItemCard';
import { useCustomReportChartSettingsForm } from './hooks/useCustomReportChartSettingsForm';
import { CustomReportChartSettingsFormProps } from './types';

const ADDING_DISABLED_TOOLTIP = 'Добавление недоступно';

const useCustomReportChartKindSelect = makeUseEnumSelect(
  CustomReportChartKindEnum,
  enumValue =>
    CUSTOM_REPORT_CHART_KINDS_DICT[enumValue as CustomReportChartKindEnum]
);

export const CustomReportChartSettingsForm: React.FC<
  CustomReportChartSettingsFormProps
> = props => {
  const { className, onSettingsFormSave } = props;

  const {
    shouldUseAggFormula,

    useBlueprintSourceFieldsInterface,
    useCustomReportChartAxesInterface,

    formContext,

    yAxesArrayItems,
    addToYAxesArray,
    removeFromYAxesArray,

    availableForXAxisSelectItems,
    availableForYAxisMenuItems,
  } = useCustomReportChartSettingsForm(props);
  const { getSourceFieldById } = useBlueprintSourceFieldsInterface;

  const { renderSelectElement: renderCustomReportChartKindSelect } =
    useCustomReportChartKindSelect({
      name: 'kind',
      label: 'Тип',
      onValueChange: newKind => {
        const firstYAxis = formContext.getValues().yAxes.at(0);
        if (
          firstYAxis &&
          (newKind?.id as CustomReportChartKindEnum) ===
            CustomReportChartKindEnum.Pie
        ) {
          formContext.setValue('yAxes', [firstYAxis]);
        }
      },
    });
  const selectedChartKind = formContext.watch('kind');

  const isPieChartSelected =
    selectedChartKind === CustomReportChartKindEnum.Pie;

  const isAddingYAxesDisabled =
    !availableForYAxisMenuItems.length ||
    (isPieChartSelected && yAxesArrayItems.length === 1);

  const selectedXAxis = formContext.watch('xAxis');

  return (
    <Form
      {...{
        className: clsx(
          'items-start',
          contentGriStyles.autoGridFixedContent,
          className
        ),
        formContext,
        id: CUSTOM_REPORT_CHART_SETTINGS_FORM_ID,
        onSubmit: formContext.handleSubmit(onSettingsFormSave),
      }}
    >
      <ReportCardBuilderFormSection title="Диаграмма">
        <Input
          {...{
            name: 'name',
            label: 'Название',
            htmlInputProps: {
              autoFocus: true,
            },
          }}
        />
        {renderCustomReportChartKindSelect()}
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection
        title={isPieChartSelected ? 'Ярлык' : 'Ось X'}
      >
        <Select<(typeof availableForXAxisSelectItems)[number]>
          {...{
            name: 'xAxis',
            withFormContext: false,
            label: 'Значение',
            items: availableForXAxisSelectItems,
            rawValue:
              selectedXAxis && selectedXAxis.valueKey
                ? getCustomReportValueConfigKey(selectedXAxis.valueKey)
                : selectedXAxis?.blueprintSourceFieldID,
            getItemValue: item =>
              isCustomReportSettingsValue(item)
                ? getCustomReportValueConfigKey(item)
                : item.blueprintSourceField.id,
            getItemText: item => {
              if (!item) {
                return '';
              }

              return isCustomReportSettingsValue(item)
                ? getValueColumnName(item, getSourceFieldById)
                : item.blueprintSourceField.name;
            },
            onValueChange: newValue => {
              if (!newValue) {
                formContext.setValue('xAxis', null);
              } else if (isCustomReportSettingsValue(newValue)) {
                formContext.setValue('xAxis', {
                  blueprintSourceFieldID: undefined,
                  valueKey: mapCustomReportValueKeyToForm(newValue),
                });
              } else {
                formContext.setValue('xAxis', {
                  blueprintSourceFieldID: newValue.blueprintSourceField.id,
                  valueKey: undefined,
                });
              }
            },
          }}
        />
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection<(typeof availableForYAxisMenuItems)[number]>
        {...{
          title: 'Значения',
          withChildrenCount: true,
          noChildrenMessage: 'Список значений пока пуст',
          contextMenuButtonProps: {
            items: availableForYAxisMenuItems,
            isDisabled: isAddingYAxesDisabled,
            tooltip: isAddingYAxesDisabled
              ? ADDING_DISABLED_TOOLTIP
              : undefined,
            menuProps: {
              onItemPress: addToYAxesArray,
            },
          },
        }}
      >
        {yAxesArrayItems.map((yAxisConfig, index) => (
          <YAxisConfigArrayItemCard
            key={yAxisConfig.id}
            {...{
              fieldPrefix: `yAxes.${index}.`,
              yAxisConfig,
              shouldUseAggFormula,
              shouldAllowSecondScale: !isPieChartSelected,
              useCustomReportChartAxesInterface,
              onDelete: () => {
                removeFromYAxesArray(index);
              },
            }}
          />
        ))}
      </ReportCardBuilderFormSection>
    </Form>
  );
};
