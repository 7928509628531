import clsx from 'clsx';

import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { useSkeletonContext } from '~/shared/components/Skeleton';
import { Tooltip } from '~/shared/components/Tooltip';
import {
  renderTypography,
  Typography,
  TypographyVariants,
} from '~/shared/components/Typography';
import { useAccordion } from '~/shared/hooks/useAccordion';
import { Falsy } from '~/shared/types/utility';

import { ITEM_ACTIONS_COLUMN_KEY, Table } from '../..';
import { getSpanValue } from '../../helpers';
import styles from '../../index.module.scss';
import {
  CellPropOrGetCellProp,
  GetCellProp,
  TableColumnConfigInner,
  TableProps,
  TableRowConfig,
} from '../../types';

interface Props<T extends object, CellData> {
  /**
   * All props passed to the table component
   */
  tableProps: TableProps<T, CellData>;
  /**
   * Item to render
   */
  rowItem: T;
  /**
   * Item index in the items array
   */
  rowItemIndex: number;
  /**
   * All items array
   */
  rowsArray: T[];
  /**
   * We can't use auto rows cause we need to use grid lines in HiddenDataCaption,
   * so we set it explicitly for each row
   */
  gridRow: number;

  /**
   * If true, renders additional column for an expand row icon
   */
  isTableWithExpandableRows: boolean;
  /**
   * If true, renders a row as expandable
   */
  isExpandableRow: boolean;

  /**
   * Column configs without grouping columns
   */
  flattenColumnConfigs: TableColumnConfigInner<T>[];

  /**
   * Typography variant, used in the cell
   */
  cellTypographyVariant: TypographyVariants;
}

export const TableRow = <T extends object, CellData>({
  rowItem,
  rowItemIndex,
  rowsArray,
  gridRow,

  isTableWithExpandableRows,
  isExpandableRow,

  flattenColumnConfigs,

  cellTypographyVariant,

  tableProps,
}: Props<T, CellData>) => {
  const {
    getExpandableRows,
    renderExpandableRowContent,
    getRowClassName,
    getRowTypographyVariant,

    onItemClick,

    itemActionCellClassName,
    renderItemActions,

    shouldUnmountExpandableRows = true,
  } = tableProps;

  const {
    isOpen,
    shouldRenderContent,

    childrenContainerRef,

    openButtonProps,
    childrenWrapperProps,
  } = useAccordion({
    shouldUnmountClosedChildren: shouldUnmountExpandableRows,
  });

  const { renderWithSkeleton } = useSkeletonContext();

  let cellsToSkip = 0;

  const rowClassName = getRowClassName?.(rowItem, rowItemIndex);

  return (
    <>
      <tr
        {...{
          className: clsx(
            styles.row,
            rowClassName,
            onItemClick && styles.clickable,
            isOpen && isExpandableRow && styles.expandedRow
          ),
          style: {
            gridRow,
          },
          onClick: e => onItemClick?.(rowItem, e),
        }}
      >
        {isTableWithExpandableRows && (
          <td
            className={clsx(
              styles.expandableCell,
              !rowClassName && styles.cellWithBackground,
              // Apply row className for sticky cell to avoid background glitching
              flattenColumnConfigs.at(0)?.isSticky && rowClassName
            )}
          >
            {isExpandableRow && (
              <div className="grid place-items-center">
                <Icon
                  {...{
                    variant: IconVariants.chevronDown,
                    rotate: isOpen ? RotateVariants.up : RotateVariants.left,
                    ...openButtonProps,
                  }}
                />
              </div>
            )}
          </td>
        )}
        {flattenColumnConfigs.map(config => {
          if (cellsToSkip) {
            cellsToSkip -= 1;
            return null;
          }

          const {
            itemField,
            renderCellContent = cellItem =>
              itemField ? cellItem[itemField]?.toString() : null,
            getColSpan,
          } = config;

          const cellContent = renderCellContent(
            rowItem,
            rowItemIndex,
            rowsArray
          );

          const currentColSpan = getColSpan?.(rowItem) || undefined;
          cellsToSkip = currentColSpan ? currentColSpan - 1 : 0;

          const getCellConfigValue = <PropValue extends any>(
            propOrGetProp: CellPropOrGetCellProp<T, PropValue>
          ): PropValue | Falsy =>
            typeof propOrGetProp === 'function'
              ? (propOrGetProp as GetCellProp<T, PropValue>)(
                  rowItem,
                  rowItemIndex,
                  rowsArray
                )
              : propOrGetProp;

          const cellClassName = getCellConfigValue(config.cellClassName);

          const currentCellTypographyProps =
            getCellConfigValue(config.cellTypographyProps) || {};

          const currentRowTypographyVariant = getRowTypographyVariant?.(
            rowItem,
            rowItemIndex
          );

          const currentCellTypographyVariant =
            currentCellTypographyProps.variant ||
            currentRowTypographyVariant ||
            cellTypographyVariant;

          const cellTooltip = getCellConfigValue(config.cellTooltip);
          const cellTitle = getCellConfigValue(config.cellTitle) || undefined;

          let cellContentElement = cellContent;

          if (cellTooltip) {
            cellContentElement = (
              <Tooltip content={cellTooltip}>
                <div className={styles.cellTooltip}>{cellContentElement}</div>
              </Tooltip>
            );
          }

          return (
            <td
              key={config.key}
              {...{
                className: clsx(
                  styles.cell,
                  // We apply default background to avoid glitches in sticky cells,
                  // but only if we don't define rowClassName (sometimes we use it to change single row background)
                  !rowClassName && styles.cellWithBackground,
                  config.columnClassName,
                  cellClassName,
                  config.isSticky && styles.stickyCell,
                  // Apply row className for sticky cell to avoid background glitching
                  config.isSticky && rowClassName,
                  config.isNestedLeft && styles.groupedCellLeft,
                  config.isNestedRight && styles.groupedCellRight
                ),
                colSpan: currentColSpan,
                style: {
                  gridColumn: getSpanValue(currentColSpan),
                },
              }}
            >
              {renderTypography(
                {
                  ...currentCellTypographyProps,
                  variant: currentCellTypographyVariant,
                  title: cellTitle,
                  children: cellContentElement,
                },
                renderWithSkeleton
              )}
            </td>
          );
        })}
        {renderItemActions && (
          <td
            key={ITEM_ACTIONS_COLUMN_KEY}
            {...{
              className: clsx(
                styles.itemActionCell,
                !rowClassName && styles.cellWithBackground,
                typeof itemActionCellClassName === 'string'
                  ? itemActionCellClassName
                  : itemActionCellClassName?.(rowItem)
              ),
            }}
          >
            <Typography
              withSkeleton={false}
              variant={TypographyVariants.bodySmall}
            >
              <div className={styles.itemAction}>
                {renderItemActions(rowItem)}
              </div>
            </Typography>
          </td>
        )}
      </tr>
      {isExpandableRow && (
        <tr
          {...{
            className: styles.expandableRow,
            style: {
              gridRow: gridRow + 1,
            },
          }}
        >
          <td
            {...{
              className: isOpen ? styles.expandedCell : undefined,
              colSpan:
                1 + flattenColumnConfigs.length + (renderItemActions ? 1 : 0),
            }}
          >
            <div
              {...{
                className: styles.collapsibleRowContent,
                ...childrenWrapperProps,
              }}
            >
              <div
                className={
                  renderExpandableRowContent
                    ? styles.collapsibleChildrenContainerWithRenderContent
                    : styles.collapsibleChildrenContainer
                }
                data-is-expandable-row={!!renderExpandableRowContent}
                ref={childrenContainerRef}
              >
                {shouldRenderContent && renderExpandableRowContent?.(rowItem)}
                {shouldRenderContent && !!getExpandableRows && (
                  <Table<TableRowConfig<T, CellData>, CellData>
                    {...({
                      ...tableProps,
                      withCustomScroll: false,
                      isTableWithExpandableRows,
                      className: styles.nestedTable,
                      items: getExpandableRows(rowItem),
                    } as TableProps<TableRowConfig<T, CellData>, CellData>)}
                  />
                )}
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
