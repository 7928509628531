import React, { useState } from 'react';

import {
  ExportEventsEnum,
  ExportJobStatus,
  ExportProvidersEnum,
} from '@graphql-types';
import R from 'ramda';
import * as yup from 'yup';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';
import { downloadFile } from '~/shared/helpers/downloadFile';
import { oneOfEnum } from '~/shared/helpers/yup';
import { usePolling } from '~/shared/hooks/usePolling';

import {
  DatePeriodPicker,
  REQUIRED_DATE_PERIOD_FORM_SCHEMA,
} from '~/services/dateTime';
import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import {
  InjectedModalProps,
  Modal,
  ModalComponentProps,
} from '~/services/modals';
import {
  Callout,
  NotificationVariants,
  useNotifications,
} from '~/services/notifications';

import { FarmAsyncSelect } from '~/entities/farms';

import formStyles from '~/styles/modules/form.module.scss';

import { useExportCompanyToFileMutation } from '../../../../gql/mutations/exportCompanyToFile.graphql';
import {
  ExportJobsDocument,
  ExportJobsQuery,
  ExportJobsQueryVariables,
} from '../../../../gql/queries/exportJobs.graphql';
import { useDetailedCompany } from '../../../../hooks';

interface Props
  extends React.PropsWithChildren,
    InjectedModalProps<Props>,
    Pick<ModalComponentProps, 'title' | 'withOpenAnimation'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * ID of the company to import file to
   */
  companyID: string;
}

const FORM_ID = 'SelexExportModalForm';

const SCHEMA = yup.object({
  farmID: yup.string().required(), // ID!
  period: REQUIRED_DATE_PERIOD_FORM_SCHEMA,
  eventsToExport: yup
    .array(oneOfEnum(ExportEventsEnum).required())
    .default(Object.values(ExportEventsEnum)),
});

type SelexExportFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type SelexExportFormTransformedType = InferValidatedSchema<typeof SCHEMA>;

const EXPORT_EVENTS_DICT: Record<ExportEventsEnum, string> = {
  [ExportEventsEnum.Exit]: 'Выбытие',
  [ExportEventsEnum.Weight]: 'Вес',
};
const useExportEventsSelect = makeUseEnumSelect(
  ExportEventsEnum,
  enumValue => EXPORT_EVENTS_DICT[enumValue as ExportEventsEnum]
);
const ExportEventsSelect = makeSelectComponentFromHook(useExportEventsSelect);

export const SelexExportModalForm: React.FC<Props> = ({
  className,
  companyID,
  children,

  close,

  ...modalProps
}) => {
  const { sendNeutralToast } = useNotifications();

  const [exportCompanyToFile, { client }] = useExportCompanyToFileMutation();

  const { isDetailedCompanyLoading, companyDetailed, companyDetailedPromise } =
    useDetailedCompany(companyID);

  const formContext = useForm<
    SelexExportFormType,
    SelexExportFormTransformedType
  >({
    schema: SCHEMA,

    defaultValues: () =>
      companyDetailedPromise.then(detailedCompany => ({
        ...SCHEMA.getDefault(),
        farmID: detailedCompany?.farms.nodes.at(0)?.id,
      })),
  });

  const [exportJobIdToPoll, setExportJobIdToPoll] = useState<string>();
  const isPolling = !!exportJobIdToPoll;

  usePolling({
    pollData: () =>
      exportJobIdToPoll
        ? client.query<ExportJobsQuery, ExportJobsQueryVariables>({
            query: ExportJobsDocument,
            variables: {
              ids: [exportJobIdToPoll],
            },
            fetchPolicy: 'no-cache',
          })
        : Promise.reject(new Error('exportJobIdToPoll is not set')),
    isPolling,
    getShouldStop: ({ data }) =>
      data.exportJobs.nodes.at(0)?.status === ExportJobStatus.Done,
    onShouldStopConditionMet: ({ data }) => {
      const jobFile = data.exportJobs.nodes.at(0)?.file;
      if (!jobFile) return;
      downloadFile(jobFile.downloadUrl, jobFile.name, jobFile.extension);
      sendNeutralToast({
        message: 'Скачивание начнётся автоматически',
        functionButtonProps: {
          children: 'Скачать',
          onPress: () =>
            downloadFile(jobFile.downloadUrl, jobFile.name, jobFile.extension),
        },
      });
      close();
    },
  });

  const handleSubmit = (form: SelexExportFormTransformedType) => {
    exportCompanyToFile({
      variables: {
        input: {
          ...form,
          provider: ExportProvidersEnum.Selex,
          companyID,
        },
      },
    }).then(({ data }) => {
      setExportJobIdToPoll(data?.exportCompanyToFile.id);
    });
  };

  return (
    <Modal
      {...{
        className,
        ...modalProps,
        submitButtonProps: {
          form: FORM_ID,
          children: 'Скачать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
        renderButtons: isPolling ? R.always(null) : undefined,
      }}
    >
      {isPolling && (
        <DataBlockedMessage
          {...{
            isLarge: true,
            isLoading: true,
            message: 'Подготавливаем данные',
            description: 'Пожалуйста, подождите',
          }}
        />
      )}
      {!isPolling && (
        <>
          {children}
          <Form
            {...{
              className: formStyles.singleColumnForm,
              formContext,
              id: FORM_ID,
              onSubmit: formContext.handleSubmit(handleSubmit),
            }}
          >
            <FarmAsyncSelect
              {...{
                name: 'farmID',
                label: 'Ферма',
                items: companyDetailed?.farms.nodes ?? [],
                asyncProps: {
                  isLoading: isDetailedCompanyLoading,
                },
              }}
            />
            <DatePeriodPicker
              {...{
                name: 'period',
                label: 'Период',
              }}
            />
            <Callout
              className="mt-8"
              variant={NotificationVariants.info}
              message="События, которые обязательны для отчётности, будут выгружены в любом случае, отменить их выгрузку нельзя. Ниже вы можете выбрать дополнительные события для выгрузки"
            />
            <ExportEventsSelect
              {...{
                name: 'eventsToExport',
                label: 'События для выгрузки',
                isMulti: true,
              }}
            />
          </Form>
        </>
      )}
    </Modal>
  );
};
