import React, { ReactNode, useRef } from 'react';

import { useCheckbox } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { mergeProps, mergeRefs } from '~/shared/helpers/mergeProps';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { BaseFieldProps } from '~/shared/types/controls';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

interface Props extends BaseFieldProps<boolean> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Size of the star
   */
  size?: SizeVariants;
  /**
   * Tooltip for icon
   */
  tooltip?: ReactNode;
}

const FavoriteInner = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      name,

      value,
      defaultValue = false,
      onValueChange,

      size = SizeVariants.size16,
      tooltip,

      ...other
    }: Props,
    forwardedRef
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);

    const [isSelected, setSelected] = useControllableState(
      value,
      newValue => onValueChange?.(!!newValue),
      defaultValue
    );

    const { inputProps, isPressed, labelProps } = useCheckbox(
      {
        name,
        'aria-label': name,

        ...other,
      },
      {
        isSelected,
        setSelected,
        toggle: () => {
          setSelected(state => !state);
        },
      },
      innerRef
    );

    // :focus-visible is not working with usePress correctly, so we use react-aria solution
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
      <label
        {...{
          htmlFor: inputProps.id,
          className: clsx(className, styles.root, {
            [styles.selected]: isSelected,
            [styles.focused]: isFocusVisible,
            [styles.pressed]: isPressed,
          }),
          'data-is-focus-visible': isFocusVisible,
          'aria-selected': isSelected,
          ...labelProps,
        }}
      >
        <VisuallyHidden>
          <input
            {...{
              ...mergeProps(inputProps, focusProps),
              // useCheckbox ignores the tabIndex prop
              tabIndex: other.tabIndex,
              ref: mergeRefs(forwardedRef, innerRef),
            }}
          />
        </VisuallyHidden>

        <Icon
          {...{
            className: 'full-height',
            variant: IconVariants.star,
            size,
            tooltip,
          }}
        />
      </label>
    );
  }
);

export const Favorite = withOptionalFormController<Props, boolean>({
  defaultValue: false,
})(FavoriteInner);
