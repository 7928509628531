/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './../../../../routes/+__root'
import { Route as CompanyIdLayoutImport } from './../../../../routes/+$companyId/+_layout'
import { Route as SelectCompanyIndexImport } from './../../../../routes/+select-company/+index'
import { Route as LoginIndexImport } from './../../../../routes/+login/+index'
import { Route as CompanyIdIndexImport } from './../../../../routes/+$companyId/+index'
import { Route as CompanyIdLayoutUserIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+index'
import { Route as CompanyIdLayoutUserCowsCowIdImport } from './../../../../routes/+$companyId/+_layout/+user/+cows/+$cowId'
import { Route as CompanyIdLayoutUserBlueprintBlueprintIdImport } from './../../../../routes/+$companyId/+_layout/+user/+blueprint/+$blueprintId'
import { Route as CompanyIdLayoutUserAnalyticsReproductionImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+reproduction'
import { Route as CompanyIdLayoutUserAnalyticsMilkingImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+milking'
import { Route as CompanyIdLayoutIntegratorBlueprintsBlueprintIdImport } from './../../../../routes/+$companyId/+_layout/+integrator/+blueprints/+$blueprintId'
import { Route as CompanyIdLayoutUserProductionCalendarIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+production-calendar/+index'
import { Route as CompanyIdLayoutUserPlannedInjectionsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+planned-injections/+index'
import { Route as CompanyIdLayoutUserIncidentsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+incidents/+index'
import { Route as CompanyIdLayoutUserHerdIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+herd/+index'
import { Route as CompanyIdLayoutUserEventsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+events/+index'
import { Route as CompanyIdLayoutUserEntitiesIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+entities/+index'
import { Route as CompanyIdLayoutUserAnalyticsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+index'
import { Route as CompanyIdLayoutUserCowsCowIdInfoImport } from './../../../../routes/+$companyId/+_layout/+user/+cows/+$cowId/+info'
import { Route as CompanyIdLayoutUserAnalyticsReproductionOverviewImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+reproduction/+overview'
import { Route as CompanyIdLayoutUserAnalyticsReproductionHdrAndPrImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+reproduction/+hdr-and-pr'
import { Route as CompanyIdLayoutUserAnalyticsReproductionCrImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+reproduction/+cr'
import { Route as CompanyIdLayoutUserAnalyticsMilkingByHerdImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+milking/+by-herd'
import { Route as CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdImport } from './../../../../routes/+$companyId/+_layout/+integrator/+blueprints/+edit/+$blueprintId'
import { Route as CompanyIdLayoutUserHerdPenGroupsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+herd/+penGroups/+index'
import { Route as CompanyIdLayoutUserHerdCowsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+herd/+cows/+index'
import { Route as CompanyIdLayoutUserHerdBullsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+herd/+bulls/+index'
import { Route as CompanyIdLayoutUserEventsCowEventsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+events/+cow-events/+index'
import { Route as CompanyIdLayoutUserEventsCommandsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+events/+commands/+index'
import { Route as CompanyIdLayoutUserEventsAllIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+events/+all/+index'
import { Route as CompanyIdLayoutUserEntitiesSemenDosesIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+entities/+semenDoses/+index'
import { Route as CompanyIdLayoutUserEntitiesMilkingsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+entities/+milkings/+index'
import { Route as CompanyIdLayoutUserEntitiesMilkingParlorsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+entities/+milking-parlors/+index'
import { Route as CompanyIdLayoutUserAnalyticsMonitorIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+monitor/+index'
import { Route as CompanyIdLayoutUserAnalyticsLivestockForecastIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+livestock-forecast/+index'
import { Route as CompanyIdLayoutUserAnalyticsCustomReportsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+custom-reports/+index'
import { Route as CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdImport } from './../../../../routes/+$companyId/+_layout/+user/+entities/+milking-parlors/+intervals/+$intervalId'
import { Route as CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleImport } from './../../../../routes/+$companyId/+_layout/+user/+entities/+milking-parlors/+$farmId/+schedule'
import { Route as CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+reproduction_.hdr-and-pr_.$reproductionHdrAndPrReportId'
import { Route as CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+reproduction_.cr_.$reproductionCrReportId'
import { Route as CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+milking_.by-herd_.$customMilkingReportId'
import { Route as CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+herd/+cows/+moveCowsKeys/+index'
import { Route as CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+entities/+milking-parlors/+intervals/+index'
import { Route as CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+entities/+milking-parlors/+$farmId/+index'
import { Route as CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+cows/+$cowId/+animal-fields/+index'
import { Route as CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+milking/+somatic-cells/+index'
import { Route as CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+milking/+by-milking-parlor/+index'
import { Route as CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+custom-reports/+$customReportId/+index'
import { Route as CompanyIdLayoutIntegratorBlueprintsEditMasterIndexImport } from './../../../../routes/+$companyId/+_layout/+integrator/+blueprints/+edit/+master/+index'
import { Route as CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+custom-reports/+$customReportId/+$launchHashId/+detail'
import { Route as CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+cows/+$cowId/+info/+milk-parlors/+index'
import { Route as CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+cows/+$cowId/+info/+genealogy/+index'
import { Route as CompanyIdLayoutUserCowsCowIdInfoEventsIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+cows/+$cowId/+info/+events/+index'
import { Route as CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexImport } from './../../../../routes/+$companyId/+_layout/+user/+analytics/+custom-reports/+$customReportId/+$launchHashId/+index'

// Create Virtual Routes

const CompanyIdImport = createFileRoute('/$companyId')()

// Create/Update Routes

const CompanyIdRoute = CompanyIdImport.update({
  id: '/$companyId',
  path: '/$companyId',
  getParentRoute: () => rootRoute,
} as any)

const CompanyIdLayoutRoute = CompanyIdLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => CompanyIdRoute,
} as any)

const SelectCompanyIndexRoute = SelectCompanyIndexImport.update({
  id: '/select-company/',
  path: '/select-company/',
  getParentRoute: () => rootRoute,
} as any)

const LoginIndexRoute = LoginIndexImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => rootRoute,
} as any)

const CompanyIdIndexRoute = CompanyIdIndexImport.update({
  id: '/$companyId/',
  path: '/$companyId/',
  getParentRoute: () => rootRoute,
} as any)

const CompanyIdLayoutUserIndexRoute = CompanyIdLayoutUserIndexImport.update({
  id: '/user/',
  path: '/user/',
  getParentRoute: () => CompanyIdLayoutRoute,
} as any)

const CompanyIdLayoutUserCowsCowIdRoute =
  CompanyIdLayoutUserCowsCowIdImport.update({
    id: '/user/cows/$cowId',
    path: '/user/cows/$cowId',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserBlueprintBlueprintIdRoute =
  CompanyIdLayoutUserBlueprintBlueprintIdImport.update({
    id: '/user/blueprint/$blueprintId',
    path: '/user/blueprint/$blueprintId',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsReproductionRoute =
  CompanyIdLayoutUserAnalyticsReproductionImport.update({
    id: '/user/analytics/reproduction',
    path: '/user/analytics/reproduction',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsMilkingRoute =
  CompanyIdLayoutUserAnalyticsMilkingImport.update({
    id: '/user/analytics/milking',
    path: '/user/analytics/milking',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutIntegratorBlueprintsBlueprintIdRoute =
  CompanyIdLayoutIntegratorBlueprintsBlueprintIdImport.update({
    id: '/integrator/blueprints/$blueprintId',
    path: '/integrator/blueprints/$blueprintId',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserProductionCalendarIndexRoute =
  CompanyIdLayoutUserProductionCalendarIndexImport.update({
    id: '/user/production-calendar/',
    path: '/user/production-calendar/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserPlannedInjectionsIndexRoute =
  CompanyIdLayoutUserPlannedInjectionsIndexImport.update({
    id: '/user/planned-injections/',
    path: '/user/planned-injections/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserIncidentsIndexRoute =
  CompanyIdLayoutUserIncidentsIndexImport.update({
    id: '/user/incidents/',
    path: '/user/incidents/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserHerdIndexRoute =
  CompanyIdLayoutUserHerdIndexImport.update({
    id: '/user/herd/',
    path: '/user/herd/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEventsIndexRoute =
  CompanyIdLayoutUserEventsIndexImport.update({
    id: '/user/events/',
    path: '/user/events/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEntitiesIndexRoute =
  CompanyIdLayoutUserEntitiesIndexImport.update({
    id: '/user/entities/',
    path: '/user/entities/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsIndexRoute =
  CompanyIdLayoutUserAnalyticsIndexImport.update({
    id: '/user/analytics/',
    path: '/user/analytics/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserCowsCowIdInfoRoute =
  CompanyIdLayoutUserCowsCowIdInfoImport.update({
    id: '/info',
    path: '/info',
    getParentRoute: () => CompanyIdLayoutUserCowsCowIdRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsReproductionOverviewRoute =
  CompanyIdLayoutUserAnalyticsReproductionOverviewImport.update({
    id: '/overview',
    path: '/overview',
    getParentRoute: () => CompanyIdLayoutUserAnalyticsReproductionRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsReproductionHdrAndPrRoute =
  CompanyIdLayoutUserAnalyticsReproductionHdrAndPrImport.update({
    id: '/hdr-and-pr',
    path: '/hdr-and-pr',
    getParentRoute: () => CompanyIdLayoutUserAnalyticsReproductionRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsReproductionCrRoute =
  CompanyIdLayoutUserAnalyticsReproductionCrImport.update({
    id: '/cr',
    path: '/cr',
    getParentRoute: () => CompanyIdLayoutUserAnalyticsReproductionRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsMilkingByHerdRoute =
  CompanyIdLayoutUserAnalyticsMilkingByHerdImport.update({
    id: '/by-herd',
    path: '/by-herd',
    getParentRoute: () => CompanyIdLayoutUserAnalyticsMilkingRoute,
  } as any)

const CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdRoute =
  CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdImport.update({
    id: '/integrator/blueprints/edit/$blueprintId',
    path: '/integrator/blueprints/edit/$blueprintId',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserHerdPenGroupsIndexRoute =
  CompanyIdLayoutUserHerdPenGroupsIndexImport.update({
    id: '/user/herd/penGroups/',
    path: '/user/herd/penGroups/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserHerdCowsIndexRoute =
  CompanyIdLayoutUserHerdCowsIndexImport.update({
    id: '/user/herd/cows/',
    path: '/user/herd/cows/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserHerdBullsIndexRoute =
  CompanyIdLayoutUserHerdBullsIndexImport.update({
    id: '/user/herd/bulls/',
    path: '/user/herd/bulls/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEventsCowEventsIndexRoute =
  CompanyIdLayoutUserEventsCowEventsIndexImport.update({
    id: '/user/events/cow-events/',
    path: '/user/events/cow-events/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEventsCommandsIndexRoute =
  CompanyIdLayoutUserEventsCommandsIndexImport.update({
    id: '/user/events/commands/',
    path: '/user/events/commands/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEventsAllIndexRoute =
  CompanyIdLayoutUserEventsAllIndexImport.update({
    id: '/user/events/all/',
    path: '/user/events/all/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEntitiesSemenDosesIndexRoute =
  CompanyIdLayoutUserEntitiesSemenDosesIndexImport.update({
    id: '/user/entities/semenDoses/',
    path: '/user/entities/semenDoses/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEntitiesMilkingsIndexRoute =
  CompanyIdLayoutUserEntitiesMilkingsIndexImport.update({
    id: '/user/entities/milkings/',
    path: '/user/entities/milkings/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEntitiesMilkingParlorsIndexRoute =
  CompanyIdLayoutUserEntitiesMilkingParlorsIndexImport.update({
    id: '/user/entities/milking-parlors/',
    path: '/user/entities/milking-parlors/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsMonitorIndexRoute =
  CompanyIdLayoutUserAnalyticsMonitorIndexImport.update({
    id: '/user/analytics/monitor/',
    path: '/user/analytics/monitor/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsLivestockForecastIndexRoute =
  CompanyIdLayoutUserAnalyticsLivestockForecastIndexImport.update({
    id: '/user/analytics/livestock-forecast/',
    path: '/user/analytics/livestock-forecast/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsCustomReportsIndexRoute =
  CompanyIdLayoutUserAnalyticsCustomReportsIndexImport.update({
    id: '/user/analytics/custom-reports/',
    path: '/user/analytics/custom-reports/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdRoute =
  CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdImport.update({
    id: '/user/entities/milking-parlors/intervals/$intervalId',
    path: '/user/entities/milking-parlors/intervals/$intervalId',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleRoute =
  CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleImport.update({
    id: '/user/entities/milking-parlors/$farmId/schedule',
    path: '/user/entities/milking-parlors/$farmId/schedule',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdRoute =
  CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdImport.update(
    {
      id: '/user/analytics/reproduction_/hdr-and-pr_/$reproductionHdrAndPrReportId',
      path: '/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId',
      getParentRoute: () => CompanyIdLayoutRoute,
    } as any,
  )

const CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdRoute =
  CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdImport.update(
    {
      id: '/user/analytics/reproduction_/cr_/$reproductionCrReportId',
      path: '/user/analytics/reproduction/cr/$reproductionCrReportId',
      getParentRoute: () => CompanyIdLayoutRoute,
    } as any,
  )

const CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdRoute =
  CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdImport.update({
    id: '/user/analytics/milking_/by-herd_/$customMilkingReportId',
    path: '/user/analytics/milking/by-herd/$customMilkingReportId',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexRoute =
  CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexImport.update({
    id: '/user/herd/cows/moveCowsKeys/',
    path: '/user/herd/cows/moveCowsKeys/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexRoute =
  CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexImport.update({
    id: '/user/entities/milking-parlors/intervals/',
    path: '/user/entities/milking-parlors/intervals/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexRoute =
  CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexImport.update({
    id: '/user/entities/milking-parlors/$farmId/',
    path: '/user/entities/milking-parlors/$farmId/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexRoute =
  CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexImport.update({
    id: '/animal-fields/',
    path: '/animal-fields/',
    getParentRoute: () => CompanyIdLayoutUserCowsCowIdRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexRoute =
  CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexImport.update({
    id: '/somatic-cells/',
    path: '/somatic-cells/',
    getParentRoute: () => CompanyIdLayoutUserAnalyticsMilkingRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexRoute =
  CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexImport.update({
    id: '/by-milking-parlor/',
    path: '/by-milking-parlor/',
    getParentRoute: () => CompanyIdLayoutUserAnalyticsMilkingRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexRoute =
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexImport.update({
    id: '/user/analytics/custom-reports/$customReportId/',
    path: '/user/analytics/custom-reports/$customReportId/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutIntegratorBlueprintsEditMasterIndexRoute =
  CompanyIdLayoutIntegratorBlueprintsEditMasterIndexImport.update({
    id: '/integrator/blueprints/edit/master/',
    path: '/integrator/blueprints/edit/master/',
    getParentRoute: () => CompanyIdLayoutRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailRoute =
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailImport.update(
    {
      id: '/user/analytics/custom-reports/$customReportId/$launchHashId/detail',
      path: '/user/analytics/custom-reports/$customReportId/$launchHashId/detail',
      getParentRoute: () => CompanyIdLayoutRoute,
    } as any,
  )

const CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexRoute =
  CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexImport.update({
    id: '/milk-parlors/',
    path: '/milk-parlors/',
    getParentRoute: () => CompanyIdLayoutUserCowsCowIdInfoRoute,
  } as any)

const CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexRoute =
  CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexImport.update({
    id: '/genealogy/',
    path: '/genealogy/',
    getParentRoute: () => CompanyIdLayoutUserCowsCowIdInfoRoute,
  } as any)

const CompanyIdLayoutUserCowsCowIdInfoEventsIndexRoute =
  CompanyIdLayoutUserCowsCowIdInfoEventsIndexImport.update({
    id: '/events/',
    path: '/events/',
    getParentRoute: () => CompanyIdLayoutUserCowsCowIdInfoRoute,
  } as any)

const CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexRoute =
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexImport.update(
    {
      id: '/user/analytics/custom-reports/$customReportId/$launchHashId/',
      path: '/user/analytics/custom-reports/$customReportId/$launchHashId/',
      getParentRoute: () => CompanyIdLayoutRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/$companyId/': {
      id: '/$companyId/'
      path: '/$companyId'
      fullPath: '/$companyId'
      preLoaderRoute: typeof CompanyIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/login/': {
      id: '/login/'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginIndexImport
      parentRoute: typeof rootRoute
    }
    '/select-company/': {
      id: '/select-company/'
      path: '/select-company'
      fullPath: '/select-company'
      preLoaderRoute: typeof SelectCompanyIndexImport
      parentRoute: typeof rootRoute
    }
    '/$companyId': {
      id: '/$companyId'
      path: '/$companyId'
      fullPath: '/$companyId'
      preLoaderRoute: typeof CompanyIdImport
      parentRoute: typeof rootRoute
    }
    '/$companyId/_layout': {
      id: '/$companyId/_layout'
      path: '/$companyId'
      fullPath: '/$companyId'
      preLoaderRoute: typeof CompanyIdLayoutImport
      parentRoute: typeof CompanyIdRoute
    }
    '/$companyId/_layout/user/': {
      id: '/$companyId/_layout/user/'
      path: '/user'
      fullPath: '/$companyId/user'
      preLoaderRoute: typeof CompanyIdLayoutUserIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/': {
      id: '/$companyId/_layout/user/analytics/'
      path: '/user/analytics'
      fullPath: '/$companyId/user/analytics'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/entities/': {
      id: '/$companyId/_layout/user/entities/'
      path: '/user/entities'
      fullPath: '/$companyId/user/entities'
      preLoaderRoute: typeof CompanyIdLayoutUserEntitiesIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/events/': {
      id: '/$companyId/_layout/user/events/'
      path: '/user/events'
      fullPath: '/$companyId/user/events'
      preLoaderRoute: typeof CompanyIdLayoutUserEventsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/herd/': {
      id: '/$companyId/_layout/user/herd/'
      path: '/user/herd'
      fullPath: '/$companyId/user/herd'
      preLoaderRoute: typeof CompanyIdLayoutUserHerdIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/incidents/': {
      id: '/$companyId/_layout/user/incidents/'
      path: '/user/incidents'
      fullPath: '/$companyId/user/incidents'
      preLoaderRoute: typeof CompanyIdLayoutUserIncidentsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/planned-injections/': {
      id: '/$companyId/_layout/user/planned-injections/'
      path: '/user/planned-injections'
      fullPath: '/$companyId/user/planned-injections'
      preLoaderRoute: typeof CompanyIdLayoutUserPlannedInjectionsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/production-calendar/': {
      id: '/$companyId/_layout/user/production-calendar/'
      path: '/user/production-calendar'
      fullPath: '/$companyId/user/production-calendar'
      preLoaderRoute: typeof CompanyIdLayoutUserProductionCalendarIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/integrator/blueprints/$blueprintId': {
      id: '/$companyId/_layout/integrator/blueprints/$blueprintId'
      path: '/integrator/blueprints/$blueprintId'
      fullPath: '/$companyId/integrator/blueprints/$blueprintId'
      preLoaderRoute: typeof CompanyIdLayoutIntegratorBlueprintsBlueprintIdImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/milking': {
      id: '/$companyId/_layout/user/analytics/milking'
      path: '/user/analytics/milking'
      fullPath: '/$companyId/user/analytics/milking'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsMilkingImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/reproduction': {
      id: '/$companyId/_layout/user/analytics/reproduction'
      path: '/user/analytics/reproduction'
      fullPath: '/$companyId/user/analytics/reproduction'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsReproductionImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/blueprint/$blueprintId': {
      id: '/$companyId/_layout/user/blueprint/$blueprintId'
      path: '/user/blueprint/$blueprintId'
      fullPath: '/$companyId/user/blueprint/$blueprintId'
      preLoaderRoute: typeof CompanyIdLayoutUserBlueprintBlueprintIdImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/cows/$cowId': {
      id: '/$companyId/_layout/user/cows/$cowId'
      path: '/user/cows/$cowId'
      fullPath: '/$companyId/user/cows/$cowId'
      preLoaderRoute: typeof CompanyIdLayoutUserCowsCowIdImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/custom-reports/': {
      id: '/$companyId/_layout/user/analytics/custom-reports/'
      path: '/user/analytics/custom-reports'
      fullPath: '/$companyId/user/analytics/custom-reports'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsCustomReportsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/livestock-forecast/': {
      id: '/$companyId/_layout/user/analytics/livestock-forecast/'
      path: '/user/analytics/livestock-forecast'
      fullPath: '/$companyId/user/analytics/livestock-forecast'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsLivestockForecastIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/monitor/': {
      id: '/$companyId/_layout/user/analytics/monitor/'
      path: '/user/analytics/monitor'
      fullPath: '/$companyId/user/analytics/monitor'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsMonitorIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/entities/milking-parlors/': {
      id: '/$companyId/_layout/user/entities/milking-parlors/'
      path: '/user/entities/milking-parlors'
      fullPath: '/$companyId/user/entities/milking-parlors'
      preLoaderRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/entities/milkings/': {
      id: '/$companyId/_layout/user/entities/milkings/'
      path: '/user/entities/milkings'
      fullPath: '/$companyId/user/entities/milkings'
      preLoaderRoute: typeof CompanyIdLayoutUserEntitiesMilkingsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/entities/semenDoses/': {
      id: '/$companyId/_layout/user/entities/semenDoses/'
      path: '/user/entities/semenDoses'
      fullPath: '/$companyId/user/entities/semenDoses'
      preLoaderRoute: typeof CompanyIdLayoutUserEntitiesSemenDosesIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/events/all/': {
      id: '/$companyId/_layout/user/events/all/'
      path: '/user/events/all'
      fullPath: '/$companyId/user/events/all'
      preLoaderRoute: typeof CompanyIdLayoutUserEventsAllIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/events/commands/': {
      id: '/$companyId/_layout/user/events/commands/'
      path: '/user/events/commands'
      fullPath: '/$companyId/user/events/commands'
      preLoaderRoute: typeof CompanyIdLayoutUserEventsCommandsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/events/cow-events/': {
      id: '/$companyId/_layout/user/events/cow-events/'
      path: '/user/events/cow-events'
      fullPath: '/$companyId/user/events/cow-events'
      preLoaderRoute: typeof CompanyIdLayoutUserEventsCowEventsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/herd/bulls/': {
      id: '/$companyId/_layout/user/herd/bulls/'
      path: '/user/herd/bulls'
      fullPath: '/$companyId/user/herd/bulls'
      preLoaderRoute: typeof CompanyIdLayoutUserHerdBullsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/herd/cows/': {
      id: '/$companyId/_layout/user/herd/cows/'
      path: '/user/herd/cows'
      fullPath: '/$companyId/user/herd/cows'
      preLoaderRoute: typeof CompanyIdLayoutUserHerdCowsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/herd/penGroups/': {
      id: '/$companyId/_layout/user/herd/penGroups/'
      path: '/user/herd/penGroups'
      fullPath: '/$companyId/user/herd/penGroups'
      preLoaderRoute: typeof CompanyIdLayoutUserHerdPenGroupsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/integrator/blueprints/edit/$blueprintId': {
      id: '/$companyId/_layout/integrator/blueprints/edit/$blueprintId'
      path: '/integrator/blueprints/edit/$blueprintId'
      fullPath: '/$companyId/integrator/blueprints/edit/$blueprintId'
      preLoaderRoute: typeof CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/milking/by-herd': {
      id: '/$companyId/_layout/user/analytics/milking/by-herd'
      path: '/by-herd'
      fullPath: '/$companyId/user/analytics/milking/by-herd'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsMilkingByHerdImport
      parentRoute: typeof CompanyIdLayoutUserAnalyticsMilkingImport
    }
    '/$companyId/_layout/user/analytics/reproduction/cr': {
      id: '/$companyId/_layout/user/analytics/reproduction/cr'
      path: '/cr'
      fullPath: '/$companyId/user/analytics/reproduction/cr'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsReproductionCrImport
      parentRoute: typeof CompanyIdLayoutUserAnalyticsReproductionImport
    }
    '/$companyId/_layout/user/analytics/reproduction/hdr-and-pr': {
      id: '/$companyId/_layout/user/analytics/reproduction/hdr-and-pr'
      path: '/hdr-and-pr'
      fullPath: '/$companyId/user/analytics/reproduction/hdr-and-pr'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrImport
      parentRoute: typeof CompanyIdLayoutUserAnalyticsReproductionImport
    }
    '/$companyId/_layout/user/analytics/reproduction/overview': {
      id: '/$companyId/_layout/user/analytics/reproduction/overview'
      path: '/overview'
      fullPath: '/$companyId/user/analytics/reproduction/overview'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsReproductionOverviewImport
      parentRoute: typeof CompanyIdLayoutUserAnalyticsReproductionImport
    }
    '/$companyId/_layout/user/cows/$cowId/info': {
      id: '/$companyId/_layout/user/cows/$cowId/info'
      path: '/info'
      fullPath: '/$companyId/user/cows/$cowId/info'
      preLoaderRoute: typeof CompanyIdLayoutUserCowsCowIdInfoImport
      parentRoute: typeof CompanyIdLayoutUserCowsCowIdImport
    }
    '/$companyId/_layout/integrator/blueprints/edit/master/': {
      id: '/$companyId/_layout/integrator/blueprints/edit/master/'
      path: '/integrator/blueprints/edit/master'
      fullPath: '/$companyId/integrator/blueprints/edit/master'
      preLoaderRoute: typeof CompanyIdLayoutIntegratorBlueprintsEditMasterIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/custom-reports/$customReportId/': {
      id: '/$companyId/_layout/user/analytics/custom-reports/$customReportId/'
      path: '/user/analytics/custom-reports/$customReportId'
      fullPath: '/$companyId/user/analytics/custom-reports/$customReportId'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/milking/by-milking-parlor/': {
      id: '/$companyId/_layout/user/analytics/milking/by-milking-parlor/'
      path: '/by-milking-parlor'
      fullPath: '/$companyId/user/analytics/milking/by-milking-parlor'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexImport
      parentRoute: typeof CompanyIdLayoutUserAnalyticsMilkingImport
    }
    '/$companyId/_layout/user/analytics/milking/somatic-cells/': {
      id: '/$companyId/_layout/user/analytics/milking/somatic-cells/'
      path: '/somatic-cells'
      fullPath: '/$companyId/user/analytics/milking/somatic-cells'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexImport
      parentRoute: typeof CompanyIdLayoutUserAnalyticsMilkingImport
    }
    '/$companyId/_layout/user/cows/$cowId/animal-fields/': {
      id: '/$companyId/_layout/user/cows/$cowId/animal-fields/'
      path: '/animal-fields'
      fullPath: '/$companyId/user/cows/$cowId/animal-fields'
      preLoaderRoute: typeof CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexImport
      parentRoute: typeof CompanyIdLayoutUserCowsCowIdImport
    }
    '/$companyId/_layout/user/entities/milking-parlors/$farmId/': {
      id: '/$companyId/_layout/user/entities/milking-parlors/$farmId/'
      path: '/user/entities/milking-parlors/$farmId'
      fullPath: '/$companyId/user/entities/milking-parlors/$farmId'
      preLoaderRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/entities/milking-parlors/intervals/': {
      id: '/$companyId/_layout/user/entities/milking-parlors/intervals/'
      path: '/user/entities/milking-parlors/intervals'
      fullPath: '/$companyId/user/entities/milking-parlors/intervals'
      preLoaderRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/herd/cows/moveCowsKeys/': {
      id: '/$companyId/_layout/user/herd/cows/moveCowsKeys/'
      path: '/user/herd/cows/moveCowsKeys'
      fullPath: '/$companyId/user/herd/cows/moveCowsKeys'
      preLoaderRoute: typeof CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/milking_/by-herd_/$customMilkingReportId': {
      id: '/$companyId/_layout/user/analytics/milking_/by-herd_/$customMilkingReportId'
      path: '/user/analytics/milking/by-herd/$customMilkingReportId'
      fullPath: '/$companyId/user/analytics/milking/by-herd/$customMilkingReportId'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/reproduction_/cr_/$reproductionCrReportId': {
      id: '/$companyId/_layout/user/analytics/reproduction_/cr_/$reproductionCrReportId'
      path: '/user/analytics/reproduction/cr/$reproductionCrReportId'
      fullPath: '/$companyId/user/analytics/reproduction/cr/$reproductionCrReportId'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/reproduction_/hdr-and-pr_/$reproductionHdrAndPrReportId': {
      id: '/$companyId/_layout/user/analytics/reproduction_/hdr-and-pr_/$reproductionHdrAndPrReportId'
      path: '/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId'
      fullPath: '/$companyId/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/entities/milking-parlors/$farmId/schedule': {
      id: '/$companyId/_layout/user/entities/milking-parlors/$farmId/schedule'
      path: '/user/entities/milking-parlors/$farmId/schedule'
      fullPath: '/$companyId/user/entities/milking-parlors/$farmId/schedule'
      preLoaderRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/entities/milking-parlors/intervals/$intervalId': {
      id: '/$companyId/_layout/user/entities/milking-parlors/intervals/$intervalId'
      path: '/user/entities/milking-parlors/intervals/$intervalId'
      fullPath: '/$companyId/user/entities/milking-parlors/intervals/$intervalId'
      preLoaderRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/': {
      id: '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/'
      path: '/user/analytics/custom-reports/$customReportId/$launchHashId'
      fullPath: '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexImport
      parentRoute: typeof CompanyIdLayoutImport
    }
    '/$companyId/_layout/user/cows/$cowId/info/events/': {
      id: '/$companyId/_layout/user/cows/$cowId/info/events/'
      path: '/events'
      fullPath: '/$companyId/user/cows/$cowId/info/events'
      preLoaderRoute: typeof CompanyIdLayoutUserCowsCowIdInfoEventsIndexImport
      parentRoute: typeof CompanyIdLayoutUserCowsCowIdInfoImport
    }
    '/$companyId/_layout/user/cows/$cowId/info/genealogy/': {
      id: '/$companyId/_layout/user/cows/$cowId/info/genealogy/'
      path: '/genealogy'
      fullPath: '/$companyId/user/cows/$cowId/info/genealogy'
      preLoaderRoute: typeof CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexImport
      parentRoute: typeof CompanyIdLayoutUserCowsCowIdInfoImport
    }
    '/$companyId/_layout/user/cows/$cowId/info/milk-parlors/': {
      id: '/$companyId/_layout/user/cows/$cowId/info/milk-parlors/'
      path: '/milk-parlors'
      fullPath: '/$companyId/user/cows/$cowId/info/milk-parlors'
      preLoaderRoute: typeof CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexImport
      parentRoute: typeof CompanyIdLayoutUserCowsCowIdInfoImport
    }
    '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/detail': {
      id: '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/detail'
      path: '/user/analytics/custom-reports/$customReportId/$launchHashId/detail'
      fullPath: '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId/detail'
      preLoaderRoute: typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailImport
      parentRoute: typeof CompanyIdLayoutImport
    }
  }
}

// Create and export the route tree

interface CompanyIdLayoutUserAnalyticsMilkingRouteChildren {
  CompanyIdLayoutUserAnalyticsMilkingByHerdRoute: typeof CompanyIdLayoutUserAnalyticsMilkingByHerdRoute
  CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexRoute: typeof CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexRoute
  CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexRoute: typeof CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexRoute
}

const CompanyIdLayoutUserAnalyticsMilkingRouteChildren: CompanyIdLayoutUserAnalyticsMilkingRouteChildren =
  {
    CompanyIdLayoutUserAnalyticsMilkingByHerdRoute:
      CompanyIdLayoutUserAnalyticsMilkingByHerdRoute,
    CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexRoute:
      CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexRoute,
    CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexRoute:
      CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexRoute,
  }

const CompanyIdLayoutUserAnalyticsMilkingRouteWithChildren =
  CompanyIdLayoutUserAnalyticsMilkingRoute._addFileChildren(
    CompanyIdLayoutUserAnalyticsMilkingRouteChildren,
  )

interface CompanyIdLayoutUserAnalyticsReproductionRouteChildren {
  CompanyIdLayoutUserAnalyticsReproductionCrRoute: typeof CompanyIdLayoutUserAnalyticsReproductionCrRoute
  CompanyIdLayoutUserAnalyticsReproductionHdrAndPrRoute: typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrRoute
  CompanyIdLayoutUserAnalyticsReproductionOverviewRoute: typeof CompanyIdLayoutUserAnalyticsReproductionOverviewRoute
}

const CompanyIdLayoutUserAnalyticsReproductionRouteChildren: CompanyIdLayoutUserAnalyticsReproductionRouteChildren =
  {
    CompanyIdLayoutUserAnalyticsReproductionCrRoute:
      CompanyIdLayoutUserAnalyticsReproductionCrRoute,
    CompanyIdLayoutUserAnalyticsReproductionHdrAndPrRoute:
      CompanyIdLayoutUserAnalyticsReproductionHdrAndPrRoute,
    CompanyIdLayoutUserAnalyticsReproductionOverviewRoute:
      CompanyIdLayoutUserAnalyticsReproductionOverviewRoute,
  }

const CompanyIdLayoutUserAnalyticsReproductionRouteWithChildren =
  CompanyIdLayoutUserAnalyticsReproductionRoute._addFileChildren(
    CompanyIdLayoutUserAnalyticsReproductionRouteChildren,
  )

interface CompanyIdLayoutUserCowsCowIdInfoRouteChildren {
  CompanyIdLayoutUserCowsCowIdInfoEventsIndexRoute: typeof CompanyIdLayoutUserCowsCowIdInfoEventsIndexRoute
  CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexRoute: typeof CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexRoute
  CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexRoute: typeof CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexRoute
}

const CompanyIdLayoutUserCowsCowIdInfoRouteChildren: CompanyIdLayoutUserCowsCowIdInfoRouteChildren =
  {
    CompanyIdLayoutUserCowsCowIdInfoEventsIndexRoute:
      CompanyIdLayoutUserCowsCowIdInfoEventsIndexRoute,
    CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexRoute:
      CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexRoute,
    CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexRoute:
      CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexRoute,
  }

const CompanyIdLayoutUserCowsCowIdInfoRouteWithChildren =
  CompanyIdLayoutUserCowsCowIdInfoRoute._addFileChildren(
    CompanyIdLayoutUserCowsCowIdInfoRouteChildren,
  )

interface CompanyIdLayoutUserCowsCowIdRouteChildren {
  CompanyIdLayoutUserCowsCowIdInfoRoute: typeof CompanyIdLayoutUserCowsCowIdInfoRouteWithChildren
  CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexRoute: typeof CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexRoute
}

const CompanyIdLayoutUserCowsCowIdRouteChildren: CompanyIdLayoutUserCowsCowIdRouteChildren =
  {
    CompanyIdLayoutUserCowsCowIdInfoRoute:
      CompanyIdLayoutUserCowsCowIdInfoRouteWithChildren,
    CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexRoute:
      CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexRoute,
  }

const CompanyIdLayoutUserCowsCowIdRouteWithChildren =
  CompanyIdLayoutUserCowsCowIdRoute._addFileChildren(
    CompanyIdLayoutUserCowsCowIdRouteChildren,
  )

interface CompanyIdLayoutRouteChildren {
  CompanyIdLayoutUserIndexRoute: typeof CompanyIdLayoutUserIndexRoute
  CompanyIdLayoutUserAnalyticsIndexRoute: typeof CompanyIdLayoutUserAnalyticsIndexRoute
  CompanyIdLayoutUserEntitiesIndexRoute: typeof CompanyIdLayoutUserEntitiesIndexRoute
  CompanyIdLayoutUserEventsIndexRoute: typeof CompanyIdLayoutUserEventsIndexRoute
  CompanyIdLayoutUserHerdIndexRoute: typeof CompanyIdLayoutUserHerdIndexRoute
  CompanyIdLayoutUserIncidentsIndexRoute: typeof CompanyIdLayoutUserIncidentsIndexRoute
  CompanyIdLayoutUserPlannedInjectionsIndexRoute: typeof CompanyIdLayoutUserPlannedInjectionsIndexRoute
  CompanyIdLayoutUserProductionCalendarIndexRoute: typeof CompanyIdLayoutUserProductionCalendarIndexRoute
  CompanyIdLayoutIntegratorBlueprintsBlueprintIdRoute: typeof CompanyIdLayoutIntegratorBlueprintsBlueprintIdRoute
  CompanyIdLayoutUserAnalyticsMilkingRoute: typeof CompanyIdLayoutUserAnalyticsMilkingRouteWithChildren
  CompanyIdLayoutUserAnalyticsReproductionRoute: typeof CompanyIdLayoutUserAnalyticsReproductionRouteWithChildren
  CompanyIdLayoutUserBlueprintBlueprintIdRoute: typeof CompanyIdLayoutUserBlueprintBlueprintIdRoute
  CompanyIdLayoutUserCowsCowIdRoute: typeof CompanyIdLayoutUserCowsCowIdRouteWithChildren
  CompanyIdLayoutUserAnalyticsCustomReportsIndexRoute: typeof CompanyIdLayoutUserAnalyticsCustomReportsIndexRoute
  CompanyIdLayoutUserAnalyticsLivestockForecastIndexRoute: typeof CompanyIdLayoutUserAnalyticsLivestockForecastIndexRoute
  CompanyIdLayoutUserAnalyticsMonitorIndexRoute: typeof CompanyIdLayoutUserAnalyticsMonitorIndexRoute
  CompanyIdLayoutUserEntitiesMilkingParlorsIndexRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsIndexRoute
  CompanyIdLayoutUserEntitiesMilkingsIndexRoute: typeof CompanyIdLayoutUserEntitiesMilkingsIndexRoute
  CompanyIdLayoutUserEntitiesSemenDosesIndexRoute: typeof CompanyIdLayoutUserEntitiesSemenDosesIndexRoute
  CompanyIdLayoutUserEventsAllIndexRoute: typeof CompanyIdLayoutUserEventsAllIndexRoute
  CompanyIdLayoutUserEventsCommandsIndexRoute: typeof CompanyIdLayoutUserEventsCommandsIndexRoute
  CompanyIdLayoutUserEventsCowEventsIndexRoute: typeof CompanyIdLayoutUserEventsCowEventsIndexRoute
  CompanyIdLayoutUserHerdBullsIndexRoute: typeof CompanyIdLayoutUserHerdBullsIndexRoute
  CompanyIdLayoutUserHerdCowsIndexRoute: typeof CompanyIdLayoutUserHerdCowsIndexRoute
  CompanyIdLayoutUserHerdPenGroupsIndexRoute: typeof CompanyIdLayoutUserHerdPenGroupsIndexRoute
  CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdRoute: typeof CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdRoute
  CompanyIdLayoutIntegratorBlueprintsEditMasterIndexRoute: typeof CompanyIdLayoutIntegratorBlueprintsEditMasterIndexRoute
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexRoute: typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexRoute
  CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexRoute
  CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexRoute
  CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexRoute: typeof CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexRoute
  CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdRoute: typeof CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdRoute
  CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdRoute: typeof CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdRoute
  CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdRoute: typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdRoute
  CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleRoute
  CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdRoute: typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdRoute
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexRoute: typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexRoute
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailRoute: typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailRoute
}

const CompanyIdLayoutRouteChildren: CompanyIdLayoutRouteChildren = {
  CompanyIdLayoutUserIndexRoute: CompanyIdLayoutUserIndexRoute,
  CompanyIdLayoutUserAnalyticsIndexRoute:
    CompanyIdLayoutUserAnalyticsIndexRoute,
  CompanyIdLayoutUserEntitiesIndexRoute: CompanyIdLayoutUserEntitiesIndexRoute,
  CompanyIdLayoutUserEventsIndexRoute: CompanyIdLayoutUserEventsIndexRoute,
  CompanyIdLayoutUserHerdIndexRoute: CompanyIdLayoutUserHerdIndexRoute,
  CompanyIdLayoutUserIncidentsIndexRoute:
    CompanyIdLayoutUserIncidentsIndexRoute,
  CompanyIdLayoutUserPlannedInjectionsIndexRoute:
    CompanyIdLayoutUserPlannedInjectionsIndexRoute,
  CompanyIdLayoutUserProductionCalendarIndexRoute:
    CompanyIdLayoutUserProductionCalendarIndexRoute,
  CompanyIdLayoutIntegratorBlueprintsBlueprintIdRoute:
    CompanyIdLayoutIntegratorBlueprintsBlueprintIdRoute,
  CompanyIdLayoutUserAnalyticsMilkingRoute:
    CompanyIdLayoutUserAnalyticsMilkingRouteWithChildren,
  CompanyIdLayoutUserAnalyticsReproductionRoute:
    CompanyIdLayoutUserAnalyticsReproductionRouteWithChildren,
  CompanyIdLayoutUserBlueprintBlueprintIdRoute:
    CompanyIdLayoutUserBlueprintBlueprintIdRoute,
  CompanyIdLayoutUserCowsCowIdRoute:
    CompanyIdLayoutUserCowsCowIdRouteWithChildren,
  CompanyIdLayoutUserAnalyticsCustomReportsIndexRoute:
    CompanyIdLayoutUserAnalyticsCustomReportsIndexRoute,
  CompanyIdLayoutUserAnalyticsLivestockForecastIndexRoute:
    CompanyIdLayoutUserAnalyticsLivestockForecastIndexRoute,
  CompanyIdLayoutUserAnalyticsMonitorIndexRoute:
    CompanyIdLayoutUserAnalyticsMonitorIndexRoute,
  CompanyIdLayoutUserEntitiesMilkingParlorsIndexRoute:
    CompanyIdLayoutUserEntitiesMilkingParlorsIndexRoute,
  CompanyIdLayoutUserEntitiesMilkingsIndexRoute:
    CompanyIdLayoutUserEntitiesMilkingsIndexRoute,
  CompanyIdLayoutUserEntitiesSemenDosesIndexRoute:
    CompanyIdLayoutUserEntitiesSemenDosesIndexRoute,
  CompanyIdLayoutUserEventsAllIndexRoute:
    CompanyIdLayoutUserEventsAllIndexRoute,
  CompanyIdLayoutUserEventsCommandsIndexRoute:
    CompanyIdLayoutUserEventsCommandsIndexRoute,
  CompanyIdLayoutUserEventsCowEventsIndexRoute:
    CompanyIdLayoutUserEventsCowEventsIndexRoute,
  CompanyIdLayoutUserHerdBullsIndexRoute:
    CompanyIdLayoutUserHerdBullsIndexRoute,
  CompanyIdLayoutUserHerdCowsIndexRoute: CompanyIdLayoutUserHerdCowsIndexRoute,
  CompanyIdLayoutUserHerdPenGroupsIndexRoute:
    CompanyIdLayoutUserHerdPenGroupsIndexRoute,
  CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdRoute:
    CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdRoute,
  CompanyIdLayoutIntegratorBlueprintsEditMasterIndexRoute:
    CompanyIdLayoutIntegratorBlueprintsEditMasterIndexRoute,
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexRoute:
    CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexRoute,
  CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexRoute:
    CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexRoute,
  CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexRoute:
    CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexRoute,
  CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexRoute:
    CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexRoute,
  CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdRoute:
    CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdRoute,
  CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdRoute:
    CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdRoute,
  CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdRoute:
    CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdRoute,
  CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleRoute:
    CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleRoute,
  CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdRoute:
    CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdRoute,
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexRoute:
    CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexRoute,
  CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailRoute:
    CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailRoute,
}

const CompanyIdLayoutRouteWithChildren = CompanyIdLayoutRoute._addFileChildren(
  CompanyIdLayoutRouteChildren,
)

interface CompanyIdRouteChildren {
  CompanyIdLayoutRoute: typeof CompanyIdLayoutRouteWithChildren
}

const CompanyIdRouteChildren: CompanyIdRouteChildren = {
  CompanyIdLayoutRoute: CompanyIdLayoutRouteWithChildren,
}

const CompanyIdRouteWithChildren = CompanyIdRoute._addFileChildren(
  CompanyIdRouteChildren,
)

export interface FileRoutesByFullPath {
  '/$companyId': typeof CompanyIdLayoutRouteWithChildren
  '/login': typeof LoginIndexRoute
  '/select-company': typeof SelectCompanyIndexRoute
  '/$companyId/user': typeof CompanyIdLayoutUserIndexRoute
  '/$companyId/user/analytics': typeof CompanyIdLayoutUserAnalyticsIndexRoute
  '/$companyId/user/entities': typeof CompanyIdLayoutUserEntitiesIndexRoute
  '/$companyId/user/events': typeof CompanyIdLayoutUserEventsIndexRoute
  '/$companyId/user/herd': typeof CompanyIdLayoutUserHerdIndexRoute
  '/$companyId/user/incidents': typeof CompanyIdLayoutUserIncidentsIndexRoute
  '/$companyId/user/planned-injections': typeof CompanyIdLayoutUserPlannedInjectionsIndexRoute
  '/$companyId/user/production-calendar': typeof CompanyIdLayoutUserProductionCalendarIndexRoute
  '/$companyId/integrator/blueprints/$blueprintId': typeof CompanyIdLayoutIntegratorBlueprintsBlueprintIdRoute
  '/$companyId/user/analytics/milking': typeof CompanyIdLayoutUserAnalyticsMilkingRouteWithChildren
  '/$companyId/user/analytics/reproduction': typeof CompanyIdLayoutUserAnalyticsReproductionRouteWithChildren
  '/$companyId/user/blueprint/$blueprintId': typeof CompanyIdLayoutUserBlueprintBlueprintIdRoute
  '/$companyId/user/cows/$cowId': typeof CompanyIdLayoutUserCowsCowIdRouteWithChildren
  '/$companyId/user/analytics/custom-reports': typeof CompanyIdLayoutUserAnalyticsCustomReportsIndexRoute
  '/$companyId/user/analytics/livestock-forecast': typeof CompanyIdLayoutUserAnalyticsLivestockForecastIndexRoute
  '/$companyId/user/analytics/monitor': typeof CompanyIdLayoutUserAnalyticsMonitorIndexRoute
  '/$companyId/user/entities/milking-parlors': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIndexRoute
  '/$companyId/user/entities/milkings': typeof CompanyIdLayoutUserEntitiesMilkingsIndexRoute
  '/$companyId/user/entities/semenDoses': typeof CompanyIdLayoutUserEntitiesSemenDosesIndexRoute
  '/$companyId/user/events/all': typeof CompanyIdLayoutUserEventsAllIndexRoute
  '/$companyId/user/events/commands': typeof CompanyIdLayoutUserEventsCommandsIndexRoute
  '/$companyId/user/events/cow-events': typeof CompanyIdLayoutUserEventsCowEventsIndexRoute
  '/$companyId/user/herd/bulls': typeof CompanyIdLayoutUserHerdBullsIndexRoute
  '/$companyId/user/herd/cows': typeof CompanyIdLayoutUserHerdCowsIndexRoute
  '/$companyId/user/herd/penGroups': typeof CompanyIdLayoutUserHerdPenGroupsIndexRoute
  '/$companyId/integrator/blueprints/edit/$blueprintId': typeof CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdRoute
  '/$companyId/user/analytics/milking/by-herd': typeof CompanyIdLayoutUserAnalyticsMilkingByHerdRoute
  '/$companyId/user/analytics/reproduction/cr': typeof CompanyIdLayoutUserAnalyticsReproductionCrRoute
  '/$companyId/user/analytics/reproduction/hdr-and-pr': typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrRoute
  '/$companyId/user/analytics/reproduction/overview': typeof CompanyIdLayoutUserAnalyticsReproductionOverviewRoute
  '/$companyId/user/cows/$cowId/info': typeof CompanyIdLayoutUserCowsCowIdInfoRouteWithChildren
  '/$companyId/integrator/blueprints/edit/master': typeof CompanyIdLayoutIntegratorBlueprintsEditMasterIndexRoute
  '/$companyId/user/analytics/custom-reports/$customReportId': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexRoute
  '/$companyId/user/analytics/milking/by-milking-parlor': typeof CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexRoute
  '/$companyId/user/analytics/milking/somatic-cells': typeof CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexRoute
  '/$companyId/user/cows/$cowId/animal-fields': typeof CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexRoute
  '/$companyId/user/entities/milking-parlors/$farmId': typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexRoute
  '/$companyId/user/entities/milking-parlors/intervals': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexRoute
  '/$companyId/user/herd/cows/moveCowsKeys': typeof CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexRoute
  '/$companyId/user/analytics/milking/by-herd/$customMilkingReportId': typeof CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdRoute
  '/$companyId/user/analytics/reproduction/cr/$reproductionCrReportId': typeof CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdRoute
  '/$companyId/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId': typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdRoute
  '/$companyId/user/entities/milking-parlors/$farmId/schedule': typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleRoute
  '/$companyId/user/entities/milking-parlors/intervals/$intervalId': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdRoute
  '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexRoute
  '/$companyId/user/cows/$cowId/info/events': typeof CompanyIdLayoutUserCowsCowIdInfoEventsIndexRoute
  '/$companyId/user/cows/$cowId/info/genealogy': typeof CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexRoute
  '/$companyId/user/cows/$cowId/info/milk-parlors': typeof CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexRoute
  '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId/detail': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailRoute
}

export interface FileRoutesByTo {
  '/$companyId': typeof CompanyIdLayoutRouteWithChildren
  '/login': typeof LoginIndexRoute
  '/select-company': typeof SelectCompanyIndexRoute
  '/$companyId/user': typeof CompanyIdLayoutUserIndexRoute
  '/$companyId/user/analytics': typeof CompanyIdLayoutUserAnalyticsIndexRoute
  '/$companyId/user/entities': typeof CompanyIdLayoutUserEntitiesIndexRoute
  '/$companyId/user/events': typeof CompanyIdLayoutUserEventsIndexRoute
  '/$companyId/user/herd': typeof CompanyIdLayoutUserHerdIndexRoute
  '/$companyId/user/incidents': typeof CompanyIdLayoutUserIncidentsIndexRoute
  '/$companyId/user/planned-injections': typeof CompanyIdLayoutUserPlannedInjectionsIndexRoute
  '/$companyId/user/production-calendar': typeof CompanyIdLayoutUserProductionCalendarIndexRoute
  '/$companyId/integrator/blueprints/$blueprintId': typeof CompanyIdLayoutIntegratorBlueprintsBlueprintIdRoute
  '/$companyId/user/analytics/milking': typeof CompanyIdLayoutUserAnalyticsMilkingRouteWithChildren
  '/$companyId/user/analytics/reproduction': typeof CompanyIdLayoutUserAnalyticsReproductionRouteWithChildren
  '/$companyId/user/blueprint/$blueprintId': typeof CompanyIdLayoutUserBlueprintBlueprintIdRoute
  '/$companyId/user/cows/$cowId': typeof CompanyIdLayoutUserCowsCowIdRouteWithChildren
  '/$companyId/user/analytics/custom-reports': typeof CompanyIdLayoutUserAnalyticsCustomReportsIndexRoute
  '/$companyId/user/analytics/livestock-forecast': typeof CompanyIdLayoutUserAnalyticsLivestockForecastIndexRoute
  '/$companyId/user/analytics/monitor': typeof CompanyIdLayoutUserAnalyticsMonitorIndexRoute
  '/$companyId/user/entities/milking-parlors': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIndexRoute
  '/$companyId/user/entities/milkings': typeof CompanyIdLayoutUserEntitiesMilkingsIndexRoute
  '/$companyId/user/entities/semenDoses': typeof CompanyIdLayoutUserEntitiesSemenDosesIndexRoute
  '/$companyId/user/events/all': typeof CompanyIdLayoutUserEventsAllIndexRoute
  '/$companyId/user/events/commands': typeof CompanyIdLayoutUserEventsCommandsIndexRoute
  '/$companyId/user/events/cow-events': typeof CompanyIdLayoutUserEventsCowEventsIndexRoute
  '/$companyId/user/herd/bulls': typeof CompanyIdLayoutUserHerdBullsIndexRoute
  '/$companyId/user/herd/cows': typeof CompanyIdLayoutUserHerdCowsIndexRoute
  '/$companyId/user/herd/penGroups': typeof CompanyIdLayoutUserHerdPenGroupsIndexRoute
  '/$companyId/integrator/blueprints/edit/$blueprintId': typeof CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdRoute
  '/$companyId/user/analytics/milking/by-herd': typeof CompanyIdLayoutUserAnalyticsMilkingByHerdRoute
  '/$companyId/user/analytics/reproduction/cr': typeof CompanyIdLayoutUserAnalyticsReproductionCrRoute
  '/$companyId/user/analytics/reproduction/hdr-and-pr': typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrRoute
  '/$companyId/user/analytics/reproduction/overview': typeof CompanyIdLayoutUserAnalyticsReproductionOverviewRoute
  '/$companyId/user/cows/$cowId/info': typeof CompanyIdLayoutUserCowsCowIdInfoRouteWithChildren
  '/$companyId/integrator/blueprints/edit/master': typeof CompanyIdLayoutIntegratorBlueprintsEditMasterIndexRoute
  '/$companyId/user/analytics/custom-reports/$customReportId': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexRoute
  '/$companyId/user/analytics/milking/by-milking-parlor': typeof CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexRoute
  '/$companyId/user/analytics/milking/somatic-cells': typeof CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexRoute
  '/$companyId/user/cows/$cowId/animal-fields': typeof CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexRoute
  '/$companyId/user/entities/milking-parlors/$farmId': typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexRoute
  '/$companyId/user/entities/milking-parlors/intervals': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexRoute
  '/$companyId/user/herd/cows/moveCowsKeys': typeof CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexRoute
  '/$companyId/user/analytics/milking/by-herd/$customMilkingReportId': typeof CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdRoute
  '/$companyId/user/analytics/reproduction/cr/$reproductionCrReportId': typeof CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdRoute
  '/$companyId/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId': typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdRoute
  '/$companyId/user/entities/milking-parlors/$farmId/schedule': typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleRoute
  '/$companyId/user/entities/milking-parlors/intervals/$intervalId': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdRoute
  '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexRoute
  '/$companyId/user/cows/$cowId/info/events': typeof CompanyIdLayoutUserCowsCowIdInfoEventsIndexRoute
  '/$companyId/user/cows/$cowId/info/genealogy': typeof CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexRoute
  '/$companyId/user/cows/$cowId/info/milk-parlors': typeof CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexRoute
  '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId/detail': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/$companyId/': typeof CompanyIdIndexRoute
  '/login/': typeof LoginIndexRoute
  '/select-company/': typeof SelectCompanyIndexRoute
  '/$companyId': typeof CompanyIdRouteWithChildren
  '/$companyId/_layout': typeof CompanyIdLayoutRouteWithChildren
  '/$companyId/_layout/user/': typeof CompanyIdLayoutUserIndexRoute
  '/$companyId/_layout/user/analytics/': typeof CompanyIdLayoutUserAnalyticsIndexRoute
  '/$companyId/_layout/user/entities/': typeof CompanyIdLayoutUserEntitiesIndexRoute
  '/$companyId/_layout/user/events/': typeof CompanyIdLayoutUserEventsIndexRoute
  '/$companyId/_layout/user/herd/': typeof CompanyIdLayoutUserHerdIndexRoute
  '/$companyId/_layout/user/incidents/': typeof CompanyIdLayoutUserIncidentsIndexRoute
  '/$companyId/_layout/user/planned-injections/': typeof CompanyIdLayoutUserPlannedInjectionsIndexRoute
  '/$companyId/_layout/user/production-calendar/': typeof CompanyIdLayoutUserProductionCalendarIndexRoute
  '/$companyId/_layout/integrator/blueprints/$blueprintId': typeof CompanyIdLayoutIntegratorBlueprintsBlueprintIdRoute
  '/$companyId/_layout/user/analytics/milking': typeof CompanyIdLayoutUserAnalyticsMilkingRouteWithChildren
  '/$companyId/_layout/user/analytics/reproduction': typeof CompanyIdLayoutUserAnalyticsReproductionRouteWithChildren
  '/$companyId/_layout/user/blueprint/$blueprintId': typeof CompanyIdLayoutUserBlueprintBlueprintIdRoute
  '/$companyId/_layout/user/cows/$cowId': typeof CompanyIdLayoutUserCowsCowIdRouteWithChildren
  '/$companyId/_layout/user/analytics/custom-reports/': typeof CompanyIdLayoutUserAnalyticsCustomReportsIndexRoute
  '/$companyId/_layout/user/analytics/livestock-forecast/': typeof CompanyIdLayoutUserAnalyticsLivestockForecastIndexRoute
  '/$companyId/_layout/user/analytics/monitor/': typeof CompanyIdLayoutUserAnalyticsMonitorIndexRoute
  '/$companyId/_layout/user/entities/milking-parlors/': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIndexRoute
  '/$companyId/_layout/user/entities/milkings/': typeof CompanyIdLayoutUserEntitiesMilkingsIndexRoute
  '/$companyId/_layout/user/entities/semenDoses/': typeof CompanyIdLayoutUserEntitiesSemenDosesIndexRoute
  '/$companyId/_layout/user/events/all/': typeof CompanyIdLayoutUserEventsAllIndexRoute
  '/$companyId/_layout/user/events/commands/': typeof CompanyIdLayoutUserEventsCommandsIndexRoute
  '/$companyId/_layout/user/events/cow-events/': typeof CompanyIdLayoutUserEventsCowEventsIndexRoute
  '/$companyId/_layout/user/herd/bulls/': typeof CompanyIdLayoutUserHerdBullsIndexRoute
  '/$companyId/_layout/user/herd/cows/': typeof CompanyIdLayoutUserHerdCowsIndexRoute
  '/$companyId/_layout/user/herd/penGroups/': typeof CompanyIdLayoutUserHerdPenGroupsIndexRoute
  '/$companyId/_layout/integrator/blueprints/edit/$blueprintId': typeof CompanyIdLayoutIntegratorBlueprintsEditBlueprintIdRoute
  '/$companyId/_layout/user/analytics/milking/by-herd': typeof CompanyIdLayoutUserAnalyticsMilkingByHerdRoute
  '/$companyId/_layout/user/analytics/reproduction/cr': typeof CompanyIdLayoutUserAnalyticsReproductionCrRoute
  '/$companyId/_layout/user/analytics/reproduction/hdr-and-pr': typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrRoute
  '/$companyId/_layout/user/analytics/reproduction/overview': typeof CompanyIdLayoutUserAnalyticsReproductionOverviewRoute
  '/$companyId/_layout/user/cows/$cowId/info': typeof CompanyIdLayoutUserCowsCowIdInfoRouteWithChildren
  '/$companyId/_layout/integrator/blueprints/edit/master/': typeof CompanyIdLayoutIntegratorBlueprintsEditMasterIndexRoute
  '/$companyId/_layout/user/analytics/custom-reports/$customReportId/': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdIndexRoute
  '/$companyId/_layout/user/analytics/milking/by-milking-parlor/': typeof CompanyIdLayoutUserAnalyticsMilkingByMilkingParlorIndexRoute
  '/$companyId/_layout/user/analytics/milking/somatic-cells/': typeof CompanyIdLayoutUserAnalyticsMilkingSomaticCellsIndexRoute
  '/$companyId/_layout/user/cows/$cowId/animal-fields/': typeof CompanyIdLayoutUserCowsCowIdAnimalFieldsIndexRoute
  '/$companyId/_layout/user/entities/milking-parlors/$farmId/': typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdIndexRoute
  '/$companyId/_layout/user/entities/milking-parlors/intervals/': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIndexRoute
  '/$companyId/_layout/user/herd/cows/moveCowsKeys/': typeof CompanyIdLayoutUserHerdCowsMoveCowsKeysIndexRoute
  '/$companyId/_layout/user/analytics/milking_/by-herd_/$customMilkingReportId': typeof CompanyIdLayoutUserAnalyticsMilkingByHerdCustomMilkingReportIdRoute
  '/$companyId/_layout/user/analytics/reproduction_/cr_/$reproductionCrReportId': typeof CompanyIdLayoutUserAnalyticsReproductionCrReproductionCrReportIdRoute
  '/$companyId/_layout/user/analytics/reproduction_/hdr-and-pr_/$reproductionHdrAndPrReportId': typeof CompanyIdLayoutUserAnalyticsReproductionHdrAndPrReproductionHdrAndPrReportIdRoute
  '/$companyId/_layout/user/entities/milking-parlors/$farmId/schedule': typeof CompanyIdLayoutUserEntitiesMilkingParlorsFarmIdScheduleRoute
  '/$companyId/_layout/user/entities/milking-parlors/intervals/$intervalId': typeof CompanyIdLayoutUserEntitiesMilkingParlorsIntervalsIntervalIdRoute
  '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdIndexRoute
  '/$companyId/_layout/user/cows/$cowId/info/events/': typeof CompanyIdLayoutUserCowsCowIdInfoEventsIndexRoute
  '/$companyId/_layout/user/cows/$cowId/info/genealogy/': typeof CompanyIdLayoutUserCowsCowIdInfoGenealogyIndexRoute
  '/$companyId/_layout/user/cows/$cowId/info/milk-parlors/': typeof CompanyIdLayoutUserCowsCowIdInfoMilkParlorsIndexRoute
  '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/detail': typeof CompanyIdLayoutUserAnalyticsCustomReportsCustomReportIdLaunchHashIdDetailRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/$companyId'
    | '/login'
    | '/select-company'
    | '/$companyId/user'
    | '/$companyId/user/analytics'
    | '/$companyId/user/entities'
    | '/$companyId/user/events'
    | '/$companyId/user/herd'
    | '/$companyId/user/incidents'
    | '/$companyId/user/planned-injections'
    | '/$companyId/user/production-calendar'
    | '/$companyId/integrator/blueprints/$blueprintId'
    | '/$companyId/user/analytics/milking'
    | '/$companyId/user/analytics/reproduction'
    | '/$companyId/user/blueprint/$blueprintId'
    | '/$companyId/user/cows/$cowId'
    | '/$companyId/user/analytics/custom-reports'
    | '/$companyId/user/analytics/livestock-forecast'
    | '/$companyId/user/analytics/monitor'
    | '/$companyId/user/entities/milking-parlors'
    | '/$companyId/user/entities/milkings'
    | '/$companyId/user/entities/semenDoses'
    | '/$companyId/user/events/all'
    | '/$companyId/user/events/commands'
    | '/$companyId/user/events/cow-events'
    | '/$companyId/user/herd/bulls'
    | '/$companyId/user/herd/cows'
    | '/$companyId/user/herd/penGroups'
    | '/$companyId/integrator/blueprints/edit/$blueprintId'
    | '/$companyId/user/analytics/milking/by-herd'
    | '/$companyId/user/analytics/reproduction/cr'
    | '/$companyId/user/analytics/reproduction/hdr-and-pr'
    | '/$companyId/user/analytics/reproduction/overview'
    | '/$companyId/user/cows/$cowId/info'
    | '/$companyId/integrator/blueprints/edit/master'
    | '/$companyId/user/analytics/custom-reports/$customReportId'
    | '/$companyId/user/analytics/milking/by-milking-parlor'
    | '/$companyId/user/analytics/milking/somatic-cells'
    | '/$companyId/user/cows/$cowId/animal-fields'
    | '/$companyId/user/entities/milking-parlors/$farmId'
    | '/$companyId/user/entities/milking-parlors/intervals'
    | '/$companyId/user/herd/cows/moveCowsKeys'
    | '/$companyId/user/analytics/milking/by-herd/$customMilkingReportId'
    | '/$companyId/user/analytics/reproduction/cr/$reproductionCrReportId'
    | '/$companyId/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId'
    | '/$companyId/user/entities/milking-parlors/$farmId/schedule'
    | '/$companyId/user/entities/milking-parlors/intervals/$intervalId'
    | '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId'
    | '/$companyId/user/cows/$cowId/info/events'
    | '/$companyId/user/cows/$cowId/info/genealogy'
    | '/$companyId/user/cows/$cowId/info/milk-parlors'
    | '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId/detail'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/$companyId'
    | '/login'
    | '/select-company'
    | '/$companyId/user'
    | '/$companyId/user/analytics'
    | '/$companyId/user/entities'
    | '/$companyId/user/events'
    | '/$companyId/user/herd'
    | '/$companyId/user/incidents'
    | '/$companyId/user/planned-injections'
    | '/$companyId/user/production-calendar'
    | '/$companyId/integrator/blueprints/$blueprintId'
    | '/$companyId/user/analytics/milking'
    | '/$companyId/user/analytics/reproduction'
    | '/$companyId/user/blueprint/$blueprintId'
    | '/$companyId/user/cows/$cowId'
    | '/$companyId/user/analytics/custom-reports'
    | '/$companyId/user/analytics/livestock-forecast'
    | '/$companyId/user/analytics/monitor'
    | '/$companyId/user/entities/milking-parlors'
    | '/$companyId/user/entities/milkings'
    | '/$companyId/user/entities/semenDoses'
    | '/$companyId/user/events/all'
    | '/$companyId/user/events/commands'
    | '/$companyId/user/events/cow-events'
    | '/$companyId/user/herd/bulls'
    | '/$companyId/user/herd/cows'
    | '/$companyId/user/herd/penGroups'
    | '/$companyId/integrator/blueprints/edit/$blueprintId'
    | '/$companyId/user/analytics/milking/by-herd'
    | '/$companyId/user/analytics/reproduction/cr'
    | '/$companyId/user/analytics/reproduction/hdr-and-pr'
    | '/$companyId/user/analytics/reproduction/overview'
    | '/$companyId/user/cows/$cowId/info'
    | '/$companyId/integrator/blueprints/edit/master'
    | '/$companyId/user/analytics/custom-reports/$customReportId'
    | '/$companyId/user/analytics/milking/by-milking-parlor'
    | '/$companyId/user/analytics/milking/somatic-cells'
    | '/$companyId/user/cows/$cowId/animal-fields'
    | '/$companyId/user/entities/milking-parlors/$farmId'
    | '/$companyId/user/entities/milking-parlors/intervals'
    | '/$companyId/user/herd/cows/moveCowsKeys'
    | '/$companyId/user/analytics/milking/by-herd/$customMilkingReportId'
    | '/$companyId/user/analytics/reproduction/cr/$reproductionCrReportId'
    | '/$companyId/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId'
    | '/$companyId/user/entities/milking-parlors/$farmId/schedule'
    | '/$companyId/user/entities/milking-parlors/intervals/$intervalId'
    | '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId'
    | '/$companyId/user/cows/$cowId/info/events'
    | '/$companyId/user/cows/$cowId/info/genealogy'
    | '/$companyId/user/cows/$cowId/info/milk-parlors'
    | '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId/detail'
  id:
    | '__root__'
    | '/$companyId/'
    | '/login/'
    | '/select-company/'
    | '/$companyId'
    | '/$companyId/_layout'
    | '/$companyId/_layout/user/'
    | '/$companyId/_layout/user/analytics/'
    | '/$companyId/_layout/user/entities/'
    | '/$companyId/_layout/user/events/'
    | '/$companyId/_layout/user/herd/'
    | '/$companyId/_layout/user/incidents/'
    | '/$companyId/_layout/user/planned-injections/'
    | '/$companyId/_layout/user/production-calendar/'
    | '/$companyId/_layout/integrator/blueprints/$blueprintId'
    | '/$companyId/_layout/user/analytics/milking'
    | '/$companyId/_layout/user/analytics/reproduction'
    | '/$companyId/_layout/user/blueprint/$blueprintId'
    | '/$companyId/_layout/user/cows/$cowId'
    | '/$companyId/_layout/user/analytics/custom-reports/'
    | '/$companyId/_layout/user/analytics/livestock-forecast/'
    | '/$companyId/_layout/user/analytics/monitor/'
    | '/$companyId/_layout/user/entities/milking-parlors/'
    | '/$companyId/_layout/user/entities/milkings/'
    | '/$companyId/_layout/user/entities/semenDoses/'
    | '/$companyId/_layout/user/events/all/'
    | '/$companyId/_layout/user/events/commands/'
    | '/$companyId/_layout/user/events/cow-events/'
    | '/$companyId/_layout/user/herd/bulls/'
    | '/$companyId/_layout/user/herd/cows/'
    | '/$companyId/_layout/user/herd/penGroups/'
    | '/$companyId/_layout/integrator/blueprints/edit/$blueprintId'
    | '/$companyId/_layout/user/analytics/milking/by-herd'
    | '/$companyId/_layout/user/analytics/reproduction/cr'
    | '/$companyId/_layout/user/analytics/reproduction/hdr-and-pr'
    | '/$companyId/_layout/user/analytics/reproduction/overview'
    | '/$companyId/_layout/user/cows/$cowId/info'
    | '/$companyId/_layout/integrator/blueprints/edit/master/'
    | '/$companyId/_layout/user/analytics/custom-reports/$customReportId/'
    | '/$companyId/_layout/user/analytics/milking/by-milking-parlor/'
    | '/$companyId/_layout/user/analytics/milking/somatic-cells/'
    | '/$companyId/_layout/user/cows/$cowId/animal-fields/'
    | '/$companyId/_layout/user/entities/milking-parlors/$farmId/'
    | '/$companyId/_layout/user/entities/milking-parlors/intervals/'
    | '/$companyId/_layout/user/herd/cows/moveCowsKeys/'
    | '/$companyId/_layout/user/analytics/milking_/by-herd_/$customMilkingReportId'
    | '/$companyId/_layout/user/analytics/reproduction_/cr_/$reproductionCrReportId'
    | '/$companyId/_layout/user/analytics/reproduction_/hdr-and-pr_/$reproductionHdrAndPrReportId'
    | '/$companyId/_layout/user/entities/milking-parlors/$farmId/schedule'
    | '/$companyId/_layout/user/entities/milking-parlors/intervals/$intervalId'
    | '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/'
    | '/$companyId/_layout/user/cows/$cowId/info/events/'
    | '/$companyId/_layout/user/cows/$cowId/info/genealogy/'
    | '/$companyId/_layout/user/cows/$cowId/info/milk-parlors/'
    | '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/detail'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  CompanyIdIndexRoute: typeof CompanyIdIndexRoute
  LoginIndexRoute: typeof LoginIndexRoute
  SelectCompanyIndexRoute: typeof SelectCompanyIndexRoute
  CompanyIdRoute: typeof CompanyIdRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  CompanyIdIndexRoute: CompanyIdIndexRoute,
  LoginIndexRoute: LoginIndexRoute,
  SelectCompanyIndexRoute: SelectCompanyIndexRoute,
  CompanyIdRoute: CompanyIdRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "+__root.tsx",
      "children": [
        "/$companyId/",
        "/login/",
        "/select-company/",
        "/$companyId"
      ]
    },
    "/$companyId/": {
      "filePath": "+$companyId/+index.tsx"
    },
    "/login/": {
      "filePath": "+login/+index.tsx"
    },
    "/select-company/": {
      "filePath": "+select-company/+index.tsx"
    },
    "/$companyId": {
      "filePath": "+$companyId",
      "children": [
        "/$companyId/_layout"
      ]
    },
    "/$companyId/_layout": {
      "filePath": "+$companyId/+_layout.tsx",
      "parent": "/$companyId",
      "children": [
        "/$companyId/_layout/user/",
        "/$companyId/_layout/user/analytics/",
        "/$companyId/_layout/user/entities/",
        "/$companyId/_layout/user/events/",
        "/$companyId/_layout/user/herd/",
        "/$companyId/_layout/user/incidents/",
        "/$companyId/_layout/user/planned-injections/",
        "/$companyId/_layout/user/production-calendar/",
        "/$companyId/_layout/integrator/blueprints/$blueprintId",
        "/$companyId/_layout/user/analytics/milking",
        "/$companyId/_layout/user/analytics/reproduction",
        "/$companyId/_layout/user/blueprint/$blueprintId",
        "/$companyId/_layout/user/cows/$cowId",
        "/$companyId/_layout/user/analytics/custom-reports/",
        "/$companyId/_layout/user/analytics/livestock-forecast/",
        "/$companyId/_layout/user/analytics/monitor/",
        "/$companyId/_layout/user/entities/milking-parlors/",
        "/$companyId/_layout/user/entities/milkings/",
        "/$companyId/_layout/user/entities/semenDoses/",
        "/$companyId/_layout/user/events/all/",
        "/$companyId/_layout/user/events/commands/",
        "/$companyId/_layout/user/events/cow-events/",
        "/$companyId/_layout/user/herd/bulls/",
        "/$companyId/_layout/user/herd/cows/",
        "/$companyId/_layout/user/herd/penGroups/",
        "/$companyId/_layout/integrator/blueprints/edit/$blueprintId",
        "/$companyId/_layout/integrator/blueprints/edit/master/",
        "/$companyId/_layout/user/analytics/custom-reports/$customReportId/",
        "/$companyId/_layout/user/entities/milking-parlors/$farmId/",
        "/$companyId/_layout/user/entities/milking-parlors/intervals/",
        "/$companyId/_layout/user/herd/cows/moveCowsKeys/",
        "/$companyId/_layout/user/analytics/milking_/by-herd_/$customMilkingReportId",
        "/$companyId/_layout/user/analytics/reproduction_/cr_/$reproductionCrReportId",
        "/$companyId/_layout/user/analytics/reproduction_/hdr-and-pr_/$reproductionHdrAndPrReportId",
        "/$companyId/_layout/user/entities/milking-parlors/$farmId/schedule",
        "/$companyId/_layout/user/entities/milking-parlors/intervals/$intervalId",
        "/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/",
        "/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/detail"
      ]
    },
    "/$companyId/_layout/user/": {
      "filePath": "+$companyId/+_layout/+user/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/entities/": {
      "filePath": "+$companyId/+_layout/+user/+entities/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/events/": {
      "filePath": "+$companyId/+_layout/+user/+events/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/herd/": {
      "filePath": "+$companyId/+_layout/+user/+herd/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/incidents/": {
      "filePath": "+$companyId/+_layout/+user/+incidents/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/planned-injections/": {
      "filePath": "+$companyId/+_layout/+user/+planned-injections/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/production-calendar/": {
      "filePath": "+$companyId/+_layout/+user/+production-calendar/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/integrator/blueprints/$blueprintId": {
      "filePath": "+$companyId/+_layout/+integrator/+blueprints/+$blueprintId.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/milking": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+milking.tsx",
      "parent": "/$companyId/_layout",
      "children": [
        "/$companyId/_layout/user/analytics/milking/by-herd",
        "/$companyId/_layout/user/analytics/milking/by-milking-parlor/",
        "/$companyId/_layout/user/analytics/milking/somatic-cells/"
      ]
    },
    "/$companyId/_layout/user/analytics/reproduction": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+reproduction.tsx",
      "parent": "/$companyId/_layout",
      "children": [
        "/$companyId/_layout/user/analytics/reproduction/cr",
        "/$companyId/_layout/user/analytics/reproduction/hdr-and-pr",
        "/$companyId/_layout/user/analytics/reproduction/overview"
      ]
    },
    "/$companyId/_layout/user/blueprint/$blueprintId": {
      "filePath": "+$companyId/+_layout/+user/+blueprint/+$blueprintId.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/cows/$cowId": {
      "filePath": "+$companyId/+_layout/+user/+cows/+$cowId.tsx",
      "parent": "/$companyId/_layout",
      "children": [
        "/$companyId/_layout/user/cows/$cowId/info",
        "/$companyId/_layout/user/cows/$cowId/animal-fields/"
      ]
    },
    "/$companyId/_layout/user/analytics/custom-reports/": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+custom-reports/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/livestock-forecast/": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+livestock-forecast/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/monitor/": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+monitor/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/entities/milking-parlors/": {
      "filePath": "+$companyId/+_layout/+user/+entities/+milking-parlors/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/entities/milkings/": {
      "filePath": "+$companyId/+_layout/+user/+entities/+milkings/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/entities/semenDoses/": {
      "filePath": "+$companyId/+_layout/+user/+entities/+semenDoses/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/events/all/": {
      "filePath": "+$companyId/+_layout/+user/+events/+all/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/events/commands/": {
      "filePath": "+$companyId/+_layout/+user/+events/+commands/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/events/cow-events/": {
      "filePath": "+$companyId/+_layout/+user/+events/+cow-events/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/herd/bulls/": {
      "filePath": "+$companyId/+_layout/+user/+herd/+bulls/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/herd/cows/": {
      "filePath": "+$companyId/+_layout/+user/+herd/+cows/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/herd/penGroups/": {
      "filePath": "+$companyId/+_layout/+user/+herd/+penGroups/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/integrator/blueprints/edit/$blueprintId": {
      "filePath": "+$companyId/+_layout/+integrator/+blueprints/+edit/+$blueprintId.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/milking/by-herd": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+milking/+by-herd.tsx",
      "parent": "/$companyId/_layout/user/analytics/milking"
    },
    "/$companyId/_layout/user/analytics/reproduction/cr": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+reproduction/+cr.tsx",
      "parent": "/$companyId/_layout/user/analytics/reproduction"
    },
    "/$companyId/_layout/user/analytics/reproduction/hdr-and-pr": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+reproduction/+hdr-and-pr.tsx",
      "parent": "/$companyId/_layout/user/analytics/reproduction"
    },
    "/$companyId/_layout/user/analytics/reproduction/overview": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+reproduction/+overview.tsx",
      "parent": "/$companyId/_layout/user/analytics/reproduction"
    },
    "/$companyId/_layout/user/cows/$cowId/info": {
      "filePath": "+$companyId/+_layout/+user/+cows/+$cowId/+info.tsx",
      "parent": "/$companyId/_layout/user/cows/$cowId",
      "children": [
        "/$companyId/_layout/user/cows/$cowId/info/events/",
        "/$companyId/_layout/user/cows/$cowId/info/genealogy/",
        "/$companyId/_layout/user/cows/$cowId/info/milk-parlors/"
      ]
    },
    "/$companyId/_layout/integrator/blueprints/edit/master/": {
      "filePath": "+$companyId/+_layout/+integrator/+blueprints/+edit/+master/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/custom-reports/$customReportId/": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+custom-reports/+$customReportId/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/milking/by-milking-parlor/": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+milking/+by-milking-parlor/+index.tsx",
      "parent": "/$companyId/_layout/user/analytics/milking"
    },
    "/$companyId/_layout/user/analytics/milking/somatic-cells/": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+milking/+somatic-cells/+index.tsx",
      "parent": "/$companyId/_layout/user/analytics/milking"
    },
    "/$companyId/_layout/user/cows/$cowId/animal-fields/": {
      "filePath": "+$companyId/+_layout/+user/+cows/+$cowId/+animal-fields/+index.tsx",
      "parent": "/$companyId/_layout/user/cows/$cowId"
    },
    "/$companyId/_layout/user/entities/milking-parlors/$farmId/": {
      "filePath": "+$companyId/+_layout/+user/+entities/+milking-parlors/+$farmId/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/entities/milking-parlors/intervals/": {
      "filePath": "+$companyId/+_layout/+user/+entities/+milking-parlors/+intervals/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/herd/cows/moveCowsKeys/": {
      "filePath": "+$companyId/+_layout/+user/+herd/+cows/+moveCowsKeys/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/milking_/by-herd_/$customMilkingReportId": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+milking_.by-herd_.$customMilkingReportId.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/reproduction_/cr_/$reproductionCrReportId": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+reproduction_.cr_.$reproductionCrReportId.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/reproduction_/hdr-and-pr_/$reproductionHdrAndPrReportId": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+reproduction_.hdr-and-pr_.$reproductionHdrAndPrReportId.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/entities/milking-parlors/$farmId/schedule": {
      "filePath": "+$companyId/+_layout/+user/+entities/+milking-parlors/+$farmId/+schedule.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/entities/milking-parlors/intervals/$intervalId": {
      "filePath": "+$companyId/+_layout/+user/+entities/+milking-parlors/+intervals/+$intervalId.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+custom-reports/+$customReportId/+$launchHashId/+index.tsx",
      "parent": "/$companyId/_layout"
    },
    "/$companyId/_layout/user/cows/$cowId/info/events/": {
      "filePath": "+$companyId/+_layout/+user/+cows/+$cowId/+info/+events/+index.tsx",
      "parent": "/$companyId/_layout/user/cows/$cowId/info"
    },
    "/$companyId/_layout/user/cows/$cowId/info/genealogy/": {
      "filePath": "+$companyId/+_layout/+user/+cows/+$cowId/+info/+genealogy/+index.tsx",
      "parent": "/$companyId/_layout/user/cows/$cowId/info"
    },
    "/$companyId/_layout/user/cows/$cowId/info/milk-parlors/": {
      "filePath": "+$companyId/+_layout/+user/+cows/+$cowId/+info/+milk-parlors/+index.tsx",
      "parent": "/$companyId/_layout/user/cows/$cowId/info"
    },
    "/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/detail": {
      "filePath": "+$companyId/+_layout/+user/+analytics/+custom-reports/+$customReportId/+$launchHashId/+detail.tsx",
      "parent": "/$companyId/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
