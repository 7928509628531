import React, { ReactNode } from 'react';

import { Link, LinkProps } from '@tanstack/react-router';
import clsx from 'clsx';

import { Badge, BadgeProps, BadgeSizes } from '~/shared/components/Badge';
import { PartialContextMenuButtonProps } from '~/shared/components/ContextMenuButton';
import {
  FunctionButton,
  FunctionButtonProps,
} from '~/shared/components/FunctionButton';
import { useSkeletonContext } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';
import { Falsy } from '~/shared/types/utility';

import { TileSizes } from '../../types';
import styles from './index.module.scss';

export interface TileProps {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Card title
   */
  title?: string;
  /**
   * Card description
   */
  description?: string;
  /**
   * Tile size
   */
  size?: TileSizes;
  /**
   * Badge, that is displayed under the description
   */
  topBadgeProps?: BadgeProps | Falsy;
  /**
   * Button for context menu
   */
  renderContextMenuButton?: (
    contextMenuButtonProps: PartialContextMenuButtonProps
  ) => ReactNode;
  /**
   * Additional custom content to display at the left-bottom corner of the tile
   */
  bottomContent?: ReactNode;
  /**
   * Props for function button to display at the right-bottom corner of the tile
   */
  functionButtonProps?: FunctionButtonProps | Falsy;

  /**
   * If true, tile is displayed in disabled state
   */
  isDisabled?: boolean;
  /**
   * Props for the tile link
   */
  linkProps?: LinkProps;
}

export const Tile: React.FC<TileProps> = ({
  className,

  title,
  description,
  topBadgeProps,
  renderContextMenuButton,
  size = TileSizes.medium,
  bottomContent,
  functionButtonProps,

  isDisabled = false,
  linkProps,
}) => {
  const { isLoading } = useSkeletonContext();

  const shouldRenderLink = !isDisabled && !isLoading;

  const ComponentToRender = shouldRenderLink ? Link : 'div';

  return (
    <ComponentToRender
      {...{
        className: clsx(
          styles.root,
          styles[size],
          isLoading && styles.loading,
          isDisabled && styles.disabled,
          className
        ),
        tabIndex: shouldRenderLink ? undefined : -1,
        ...wrapConditionalObjectElement(shouldRenderLink && linkProps),
      }}
    >
      <div className="flex gap-12">
        <div className="flex-1 flex flex-col gap-8">
          <Typography
            className={styles.title}
            variant={TypographyVariants.bodyMediumStrong}
          >
            {title}
          </Typography>
          {(!!description || isLoading) && (
            <Typography
              className={styles.description}
              variant={TypographyVariants.bodySmall}
            >
              {description}
            </Typography>
          )}
          {!!topBadgeProps && (
            <Badge
              {...{
                ...topBadgeProps,
                className: 'mt-4',
                size: BadgeSizes.medium20,
              }}
            />
          )}
        </div>
        {renderContextMenuButton?.({
          className: 'mt-2 self-start',
          isDisabled,
          functionButtonProps: {
            withOnlyAria: true,
            onClick: e => {
              e.preventDefault();
            },
          },
        })}
      </div>
      <div className="flex gap-12 mt-a">
        <div className="flex-1">{bottomContent}</div>
        {!!functionButtonProps && (
          <div className="flex items-end gap-8">
            <FunctionButton
              {...{
                ...functionButtonProps,
                withOnlyAria: true,
                onClick: e => {
                  e.preventDefault();
                },
                isDisabled,
              }}
            />
          </div>
        )}
      </div>
    </ComponentToRender>
  );
};
